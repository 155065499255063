import {
  Box,
  Button,
  Checkbox,
  Chip,
  Divider,
  Grid,
  Input,
  InputAdornment,
  InputLabel,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Select,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import ContactIcon from "../../../assets/icons/contact.svg";
import editPancilBgIcon from "../../../assets/icons/edit-pancil-with-bg.svg";
import EditProfileIcon from "../../../assets/icons/edit-profile.svg";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import StyledInput from "../../common/styled/input";
import { number, object, string } from "yup";
import PhoneInput from "react-phone-input-2";
import useGoogle from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { getAddressObject, usaStates } from "../../../utils/helper";
import toast from "react-hot-toast";
import {
  DeleteUser,
  FileUpload,
  SendSupportQuery,
} from "../../../controller/services/common";
import {
  getProfile,
  updateProfile,
} from "../../../controller/services/hospital/profile";
import tickCircle from "../../../assets/icons/tick-circle.svg";
import tickCheckedCircle from "../../../assets/icons/checked-circle.svg";
import DeleteIcon from "../../../assets/icons/delete-ac.svg";
import TickCardIcon from "../../../assets/icons/tick-card.svg";
import PasswordIcon from "../../../assets/icons/password.svg";
import TrashIcon from "../../../assets/icons/trash.svg";
import CallIcon from "../../../assets/icons/call.svg";
import TNCIcon from "../../../assets/icons/tnc.svg";
import PolicyIcon from "../../../assets/icons/policy.svg";
import EyeIcon from "../../../assets/icons/eye.svg";
import EyeSlashIcon from "../../../assets/icons/eye-slash.svg";
import { Document, Page, pdfjs } from "react-pdf";
import { useDispatch, useSelector } from "react-redux";
import "./index.css";
import CommonModal from "../../common/modals/commonModal";
import TermsAndConditions from "../../common/terms-and-policy/termsCondition";
import PrivacyPolicy from "../../common/terms-and-policy/privacyPolicy";
import { changePassword } from "../../../controller/services/auth";
import { setUserData } from "../../../redux/slices/user_slice";
import { setLoading } from "../../../redux/slices/flags_slice";
import { setBreadcrumb } from "../../../redux/slices/breadcrumb_slice";
import { experienceLevels } from "../../nurse/create-profile";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const userMenus = [
  {
    index: 0,
    title: "Edit Profile",
    Icon: EditProfileIcon,
  },
  {
    index: 1,
    title: "Change Password",
    Icon: PasswordIcon,
  },
  {
    index: 2,
    title: "Terms & Conditions",
    Icon: TNCIcon,
  },
  {
    index: 3,
    title: "Privacy Policy",
    Icon: PolicyIcon,
  },
  {
    index: 4,
    title: "Contact Us",
    Icon: CallIcon,
  },
  {
    index: 5,
    title: "Delete Account",
    Icon: TrashIcon,
  },
];
export default function EditProfile() {
  const [selected, setSelected] = useState(0);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const userType = useSelector((state) => state.user.userData?.userType);
  const dispatch = useDispatch();
  const styles = {
    color: "#0d2645",
    fontSize: {
      xs: "14px",
      sm: "15px",
      md: "15px",
      lg: "16px",
      xl: "16px",
    },
    lineHeight: "18.5px",
    fontWeight: 500,
    margin: {
      xs: 0,
      sm: "15px 0 0",
      md: "15px 0 0",
      lg: "15px 0",
      xl: "15px",
    },
    padding: {
      xs: "0 10px",
      sm: "10px 20px",
      md: "10px 20px",
      lg: "10px 20px",
      xl: "10px 20px",
    },
    borderBottom: {
      xs: "5px solid transparent",
      sm: 0,
      md: 0,
      lg: 0,
      xl: 0,
    },
    width: {
      xs: "48%",
      sm: "auto",
      md: "auto",
      lg: "auto",
      xl: "auto",
    },
    borderRadius: {
      xs: 0,
      sm: "10px",
      md: "10px",
      lg: "10px",
      xl: "10px",
    },
    float: {
      xs: "left",
      sm: "none",
      md: "none",
      lg: "none",
      xl: "none",
    },
  };

  const handleClickMenu = (item) => {
    if (item.index == 5) {
      setDeleteModalVisible(true);
    } else {
      setSelected(item.index);
    }
  };

  const handleDeleteUser = () => {
    DeleteUser()
      .then((resp) => {
        toast.success(resp.message);
        localStorage.clear();
        window.location.reload();
      })
      .catch((Err) => {
        toast.error(Err.message);
      });
  };

  useEffect(() => {
    const arr = [
      {
        title: "Profile",
        route: "",
      },
    ];
    dispatch(setBreadcrumb(arr));
  }, []);

  return (
    <Grid container item>
      <Grid xs={12} sm={4} md={4} lg={3} xl={2.5}>
        <MenuList
          sx={{
            padding: {
              xs: "0 15px !important",
              sm: "0 15px !important",
              md: "10px !important",
              lg: "10px !important",
              xl: "10px !important",
            },
            display: {
              xs: "flex",
              sm: "block",
              md: "block",
              lg: "block",
              xl: "block",
            },
            borderRadius: 0,
            borderBottom: {
              xs: "1px solid rgba(0, 0, 0, 0.12)",
              sm: 0,
              md: 0,
              lg: 0,
              xl: 0,
            },
            overflow: {
              xs: "auto",
              sm: "auto",
              md: "initial",
              lg: "initial",
              xl: "initial",
            },
          }}
          variant="selectedMenu"
        >
          {userMenus.map((item) => {
            const isActive = item.index === selected;
            return (
              <MenuItem
                key={item.index}
                onClick={() => handleClickMenu(item)}
                sx={{
                  ...styles,
                  ...(isActive && {
                    color: {
                      xs: "#38879F",
                      sm: "#fff",
                      md: "#fff",
                      lg: "#fff",
                      xl: "#fff",
                    },
                    background: {
                      xs: "transparent",
                      sm: "#38879f",
                      md: "#38879f",
                      lg: "#38879f",
                      xl: "#38879f",
                    },
                    padding: {
                      xs: "0 10px",
                      sm: "10px 20px",
                      md: "10px 20px",
                      lg: "10px 20px",
                      xl: "10px 20px",
                    },
                    borderBottom: {
                      xs: "5px solid #38879f",
                      sm: 0,
                      md: 0,
                      lg: 0,
                      xl: 0,
                    },
                    borderRadius: {
                      xs: 0,
                      sm: "10px",
                      md: "10px",
                      lg: "10px",
                      xl: "10px",
                    },
                    fontWeight: 700,
                  }),
                  "&:hover": {
                    color: "#0d2645",
                    padding: {
                      xs: "0 10px",
                      sm: "10px 20px",
                      md: "10px 20px",
                      lg: "10px 20px",
                      xl: "10px 20px",
                    },
                    borderBottom: {
                      xs: "5px solid #38879F",
                      sm: 0,
                      md: 0,
                      lg: 0,
                      xl: 0,
                    },
                  },
                }}
              >
                <ListItemIcon
                  sx={{
                    display: {
                      xs: "none !important",
                      sm: "inline-flex !important",
                      md: "inline-flex !important",
                      lg: "inline-flex !important",
                      xl: "inline-flex !important",
                    },
                  }}
                >
                  <img src={item.Icon} alt="app-icon" />
                </ListItemIcon>
                <Typography variant="inherit">{item.title}</Typography>
              </MenuItem>
            );
          })}
        </MenuList>
      </Grid>
      {/* <Grid md={1}> */}
      <Divider
        orientation="vertical"
        variant="middle"
        flexItem
        sx={{ mr: "-1px", borderColor: "#dcefe7", borderRadius: "12px" }}
      />
      {/* </Grid> */}
      <Grid
        xl={9.5}
        lg={9}
        md={8}
        xs={12}
        sm={8}
        sx={{
          height: {
            xs: "auto",
            sm: "auto",
            md: "auto",
            lg: "auto",
            xl: "auto",
          },
        }}
      >
        {GetSelectedComponent(selected, userType)}
      </Grid>
      <CommonModal
        title="Are you sure you want to 
        delete this account?"
        open={deleteModalVisible}
        icon={DeleteIcon}
        onSubmit={handleDeleteUser}
        onClose={() => setDeleteModalVisible(false)}
        button1Text={"Yes"}
        button2Text={"No"}
      />
    </Grid>
  );
}

const GetSelectedComponent = (selected, userType) => {
  switch (selected) {
    case 0:
      return userType == "nurse" ? <NurseProfile /> : <HospitalProfile />;
    case 1:
      return <ChangePasswordSettings />;
    case 2:
      return (
        <Box
          sx={{
            height: "80vh",
            overflow: "auto",
            paddingBottom: "20px",
          }}
        >
          <TermsAndConditions isUserSettings />
        </Box>
      );
    case 3:
      return (
        <Box
          sx={{
            height: "80vh",
            overflow: "auto",
            paddingBottom: "20px",
          }}
        >
          <PrivacyPolicy isUserSettings />
        </Box>
      );
    default:
      return <ContactUs />;
  }
};

const LabelStyles = {
  fontSize: "18px",
  fontWeight: 600,
  lineHeight: "21.6px",
  color: "#0d2645",
  marginBottom: "20px",
};

const HospitalProfile = () => {
  const [showList, setShowList] = useState(false);
  const [fileLink, setFileLink] = useState("");
  const [currentValues, setCurrentValues] = useState({});
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      ...currentValues,
    },
    validationSchema: object({
      hospitalName: string()
        .required("This field is required")
        .matches(/^[A-Za-z0-9 ]*$/, "Please enter valid name")
        .min(3, "Hospital Name must be at least 3 characters")
        .max(55, "Hospital Name must be at most 55 characters"),
      firstName: string()
        .required("This field is required")
        .matches(/^[A-Za-z ]*$/, "Please enter valid name")
        .min(3, "First Name must be at least 3 characters")
        .max(15, "First Name must be at most 15 characters"),
      lastName: string()
        .required("This field is required")
        .matches(/^[A-Za-z ]*$/, "Please enter valid name")
        .min(3, "Last Name must be at least 3 characters")
        .max(15, "Last Name must be at most 15 characters"),
      email: string().required("This field is required").email(),
      address: string().required("This field is required"),
      postalCode: string().required("This field is required"),
      province: string().required("This field is required"),
      city: string().required("This field is required"),
      linkedin: string().url(),
      website: string().url(),
      instagram: string().url(),
    }),
    onSubmit: (values) => handleSubmit(values),
  });
  const handleSubmit = (values) => {
    const payload = {
      firstName: values.firstName,
      lastName: values.lastName,
      address: values.address,
      city: values.city,
      hospitalName: values.hospitalName,
      description: values.description,
      hospitalLogo: fileLink,
      state: values.province,
      zip: values.postalCode,
      socialMediaLink: {
        linkedin: values.linkedin,
        instagram: values.instagram,
        website: values.website,
      },
    };
    updateProfile(payload)
      .then((resp) => {
        getProfileData();
        formik.setSubmitting(false);
        toast.success(resp.message);
      })
      .catch((Err) => {
        toast.error(Err.message);
      });
  };

  const getProfileData = () => {
    dispatch(setLoading(true));
    getProfile()
      .then((resp) => {
        console.log(resp, "fsdfsdf");
        const valuesObj = {
          ...resp.data.user,
          ...resp.data.user?.socialMediaLink,
          province: resp.data.user?.state,
          postalCode: resp.data.user?.zip,
        };
        formik.setValues(valuesObj);
        setCurrentValues(valuesObj);
        setFileLink(resp.data.user?.hospitalLogo);
        dispatch(setUserData(resp.data?.user));
        dispatch(setLoading(false));
      })
      .catch((Err) => {
        toast.error(Err.message);
      });
  };

  useEffect(() => {
    getProfileData();
  }, []);

  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = useGoogle({
    apiKey: process.env.REACT_APP_PLACES_KEY,
    // options: {
    //   componentRestrictions: { country: "IN" },
    // },
  });

  const getDetails = (place_id) => {
    setShowList(false);
    placesService?.getDetails(
      {
        placeId: place_id,
      },
      (placeDetails) => {
        const address = getAddressObject(placeDetails?.address_components);
        formik.setFieldValue("address", placeDetails?.name);
        formik.setFieldValue("city", address?.city);
        formik.setFieldValue("province", address?.region);
        formik.setFieldValue("postalCode", address?.postal_code);
      }
    );
  };

  const handleFileUpload = (e) => {
    if (e.target.files[0]?.size > 2000000) {
      toast.error("File size should be less than or equal to 2MB.");
    } else {
      const formData = new FormData();
      console.log(e.target.files[0], "fsdfsfsdf");
      formData.append("file", e.target.files[0]);
      formData.append("folder", "profile");
      FileUpload(formData)
        .then((resp) => {
          setFileLink(resp.data.data?.Location);
          formik.setTouched({ hospitalLogo: true });
        })
        .catch((Err) => {
          toast.error(Err.message);
        });
    }
  };

  console.log(formik.errors, formik.touched, "test");

  return (
    <Box
      textAlign={"left"}
      sx={{
        padding: {
          xs: "0 15px 20px",
          sm: "0 15px 20px",
          md: "30px",
          lg: "30px",
          xl: "30px",
        },
      }}
      onClick={() => setShowList(false)}
    >
      <form
        onSubmit={formik.handleSubmit}
        style={{ height: "90vh", overflow: "auto", paddingBottom: "50px" }}
      >
        <label
          style={{ position: "relative" }}
          className="ulpoad-label"
          for="file-upload"
        >
          <img
            className="flie-upload-img"
            src={fileLink ? fileLink : ContactIcon}
            alt="upload-logo"
            width={140}
            style={{ margin: "20px 0", height: "140px", borderRadius: "50%" }}
          />
          <img
            className="camera-imgg"
            src={editPancilBgIcon}
            alt="upload-logo"
            style={{
              position: "absolute",
              right: "-15px",
              bottom: "15px",
              cursor: "pointer",
            }}
          />
        </label>
        <input
          type="file"
          id="file-upload"
          itemType=""
          style={{
            display: "none",
          }}
          accept="image/*"
          onChange={handleFileUpload}
        />
        <InputLabel sx={{ ...LabelStyles }}>Hospital name </InputLabel>
        <StyledInput
          name={"hospitalName"}
          placeholder={"Enter hospital name"}
          handleChange={formik.handleChange}
          handleBlur={formik.handleBlur}
          errors={formik.errors}
          touched={formik.touched}
          value={formik.values.hospitalName}
        />
        <Divider sx={{ marginBottom: "20px" }} />
        <InputLabel sx={{ ...LabelStyles }}>Hospital description </InputLabel>
        <TextareaAutosize
          name="description"
          className="area-desc"
          placeholder="Enter Hospital Description"
          minRows={3}
          value={formik.values.description}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          style={{
            padding: "10px 5px 10px 20px",
            background: "#eff6f8",
            width: "98%",
            border: "none",
            height: "30px",
            borderRadius: "20px",
            marginBottom: "20px",
            fontFamily: "Lato, sans-serif",
            fontSize: "15px",
          }}
        />
        <Divider sx={{ marginBottom: "20px" }} />

        <InputLabel sx={{ ...LabelStyles }}>
          Contact person’s first and last name
        </InputLabel>
        <Grid container columnSpacing={2}>
          <Grid xs={12} sm={12} md={12} lg={6} xl={6} item>
            <StyledInput
              name={"firstName"}
              placeholder={"Enter first name"}
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              errors={formik.errors}
              touched={formik.touched}
              value={formik.values.firstName}
            />
          </Grid>
          <Grid xs={12} sm={12} md={12} lg={6} xl={6} item>
            <StyledInput
              name={"lastName"}
              placeholder={"Enter last name"}
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              errors={formik.errors}
              touched={formik.touched}
              value={formik.values.lastName}
            />
          </Grid>
        </Grid>

        <Grid container columnSpacing={2}>
          <Grid xs={12} sm={12} md={12} lg={6} xl={6} item>
            <InputLabel sx={{ ...LabelStyles }}>Email Address</InputLabel>
            <StyledInput
              name={"email"}
              placeholder={"Enter email address"}
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              errors={formik.errors}
              touched={formik.touched}
              value={formik.values.email}
              disabled
            />
          </Grid>
          <Grid xs={12} sm={12} md={12} lg={6} xl={6} item>
            <InputLabel sx={{ ...LabelStyles }}>Phone number</InputLabel>
            <PhoneInput
              inputExtraProps={{
                placeholder: "Enter phone number",
                searchPlaceholder: "Enter phone number",
              }}
              disabled
              inputProps={{
                name: "mobileNo",
              }}
              value={formik.values?.countryCode + formik.values?.phone}
              renderStringAsFlag=""
              placeholder="Enter phone number"
              searchPlaceholder="Enter phone number"
              country="ca"
              enableAreaCodes={false}
              copyNumbersOnly={false}
              buttonStyle={{ background: "none", border: "none" }}
              inputStyle={{
                padding: "10px 5px 10px 40px",
                background: "#eff6f8",
                color: "#0d2645",
                width: "100%",
                height: "50px",
                borderRadius: "10px",
              }}
            />
          </Grid>
        </Grid>
        <Grid container columnSpacing={2}>
          <Grid xs={12} sm={12} md={12} lg={6} xl={6} item>
            <InputLabel sx={{ ...LabelStyles }}>Address</InputLabel>
            <StyledInput
              name={"address"}
              placeholder={"Enter Address"}
              handleChange={(e) => {
                getPlacePredictions({ input: e.target.value });
                setShowList(true);
                formik.handleChange(e);
              }}
              handleBlur={formik.handleBlur}
              errors={formik.errors}
              touched={formik.touched}
              value={formik.values.address}
            />
            {!isPlacePredictionsLoading &&
              showList &&
              formik.values.address &&
              placePredictions?.length > 0 && (
                <Box
                  sx={{
                    width: "100%",
                    height: 200,
                    bottom: "20px",
                    overflow: "auto",
                    border: "1px solid #e9e9e9",
                    borderRadius: "0 0 10px 10px",
                    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                    bgcolor: "#fff",
                    margin: "auto",
                    zIndex: 9,
                  }}
                  position={"relative"}
                  bottom={20}
                >
                  {placePredictions.map((item) => {
                    return (
                      <ListItem
                        key={item?.place_id}
                        onClick={(e) => {
                          e.stopPropagation();
                          getDetails(item?.place_id);
                        }}
                        component="div"
                        disablePadding
                      >
                        <ListItemButton>
                          <ListItemText primary={item.description} />
                        </ListItemButton>
                      </ListItem>
                    );
                  })}
                </Box>
              )}
          </Grid>
          <Grid xs={12} sm={12} md={12} lg={6} xl={6} item>
            <InputLabel sx={{ ...LabelStyles }}>City</InputLabel>
            <StyledInput
              name={"city"}
              placeholder={"Enter City"}
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              errors={formik.errors}
              touched={formik.touched}
              value={formik.values.city}
            />
          </Grid>
        </Grid>
        <Grid container columnSpacing={2}>
          <Grid xs={12} sm={12} md={12} lg={6} xl={6} item>
            <InputLabel sx={{ ...LabelStyles }}>Province</InputLabel>
            <StyledInput
              name={"province"}
              placeholder={"Enter Province"}
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              errors={formik.errors}
              touched={formik.touched}
              value={formik.values.province}
            />
          </Grid>
          <Grid xs={12} sm={12} md={12} lg={6} xl={6} item>
            <InputLabel sx={{ ...LabelStyles }}>Postal Code</InputLabel>
            <StyledInput
              name={"postalCode"}
              placeholder={"Enter Postal Code"}
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              errors={formik.errors}
              touched={formik.touched}
              value={formik.values.postalCode}
            />
          </Grid>
        </Grid>
        <InputLabel sx={{ ...LabelStyles }}>Social Media Links</InputLabel>
        <Grid container columnSpacing={2}>
          <Grid
            md={3}
            xs={12}
            xm={12}
            item
            display={"flex"}
            justifyContent={"space-between"}
            flexDirection={"column"}
          >
            <StyledInput
              name={""}
              placeholder={""}
              errors={{}}
              touched={{}}
              value={"Linkedin"}
              disabled
              style={{ cursor: "default" }}
            />
            <StyledInput
              name={""}
              placeholder={""}
              errors={{}}
              touched={{}}
              value={"Website"}
              disabled
              style={{ cursor: "default" }}
            />
            <StyledInput
              name={""}
              placeholder={""}
              errors={{}}
              touched={{}}
              value={"Instagram"}
              disabled
              style={{ cursor: "default" }}
            />
          </Grid>
          <Grid xs={12} xm={12} md={9} item>
            <StyledInput
              name={"linkedin"}
              placeholder={"Enter Linkedin Link"}
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              errors={formik.errors}
              touched={formik.touched}
              value={formik.values.linkedin}
            />
            <StyledInput
              name={"website"}
              placeholder={"Enter Website Link"}
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              errors={formik.errors}
              touched={formik.touched}
              value={formik.values.website}
            />
            <StyledInput
              name={"instagram"}
              placeholder={"Enter Instagram Link"}
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              errors={formik.errors}
              touched={formik.touched}
              value={formik.values.instagram}
            />
          </Grid>
        </Grid>
        <Divider sx={{ marginBottom: "20px" }} />
        <Button
          type="submit"
          disabled={
            formik.isSubmitting ||
            Object.entries(formik.errors).length > 0 ||
            Object.keys(formik.touched)?.length === 0 ||
            (!formik.values?.website &&
            !formik.values?.linkedin &&
            !formik.values?.instagram
              ? true
              : false)
          }
          sx={{
            width: {
              xs: "100%",
              sm: "50%",
              md: "40%",
              lg: "20%",
              xl: "15%",
            },
            height: "55px",
            marginTop: "15px",
            background: "#38879f",
            color: "#fff",
            fontWeight: 600,
            borderRadius: "10px",
            "&.Mui-disabled": {
              background: "#adcdd7",
              color: "#fff",
            },
            "&:hover": {
              backgroundColor: "#38879f",
            },
          }}
        >
          Save
        </Button>
      </form>
    </Box>
  );
};

const NurseProfile = () => {
  const [fileLink, setFileLink] = useState("");
  const [currentValues, setCurrentValues] = useState({});
  const [license, setLicenseLink] = useState("");
  const [resume, setResumeLink] = useState("");
  const [licensedStates, setLicensedStates] = useState(["Select States"]);
  const [isStateChanged, setStateChanged] = useState(false);
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      ...currentValues,
    },
    validationSchema: object({
      bio: string()
        .required("This field is required")
        .min(3, "Bio must be at least 3 characters")
        .max(255, "Bio must be at most 255 characters"),
      firstName: string()
        .required("This field is required")
        .matches(/^[A-Za-z ]*$/, "Please enter valid name")
        .min(3, "First Name must be at least 3 characters")
        .max(15, "First Name must be at most 15 characters"),
      lastName: string()
        .required("This field is required")
        .matches(/^[A-Za-z ]*$/, "Please enter valid name")
        .min(3, "Last Name must be at least 3 characters")
        .max(15, "Last Name must be at most 15 characters"),
      email: string().required("This field is required").email(),
      experience: number().required("This field is required").max(50),
      experienceLevel: string().required("This field is required"),
    }),
    onSubmit: (values) => handleSubmit(values),
  });
  const handleSubmit = (values) => {
    dispatch(setLoading(true));
    const payload = {
      bio: values.bio,
      lastName: values.lastName,
      firstName: values.firstName,
      profilePicture: fileLink,
      experience: values.experience,
      experienceLevel: values.experienceLevel,
      license: license,
      resume: resume,
      licensedStates: [...usaStates].filter((i) => {
        return licensedStates.some((n) => n == i.name);
      }),
    };
    updateProfile(payload)
      .then((resp) => {
        getProfile();
        formik.setSubmitting(false);
        dispatch(setLoading(false));
        toast.success(resp.message);
        getProfileData();
      })
      .catch((Err) => {
        toast.error(Err.message);
      });
  };

  const getProfileData = () => {
    dispatch(setLoading(true));
    getProfile()
      .then((resp) => {
        const valuesObj = {
          ...resp.data.user,
        };
        formik.setValues(valuesObj);
        setCurrentValues(valuesObj);
        setLicensedStates(valuesObj?.licensedStates?.map((i) => i.name));
        setFileLink(resp.data.user?.profilePicture);
        setLicenseLink(resp.data.user?.license);
        setResumeLink(resp.data.user?.resume);
        dispatch(setUserData(resp.data?.user));
        dispatch(setLoading(false));
      })
      .catch((Err) => {
        toast.error(Err.message);
      });
  };

  useEffect(() => {
    getProfileData();
  }, []);

  const handleFileUpload = (e, type) => {
    if (e.target.files[0]?.size > 2000000) {
      toast.error("File size should be less than or equal to 2MB.");
    } else {
      dispatch(setLoading(true));
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      formData.append("folder", "profile");
      FileUpload(formData)
        .then((resp) => {
          const location = resp.data.data?.Location;
          if (type === "profilepic") {
            setFileLink(location);
            formik.setTouched({ profilePicture: true });
          } else if (type === "resume") {
            formik.setTouched({ resume: true });
            setResumeLink(location);
          } else {
            setLicenseLink(location);
            formik.setTouched({ license: true });
          }
          dispatch(setLoading(false));
        })
        .catch((Err) => {
          toast.error(Err.message);
        });
    }
  };

  const handleChangeStates = (i) => {
    setStateChanged(true);
    const filteredArr = licensedStates.filter((n) => n != i);
    setLicensedStates(filteredArr);
  };

  return (
    <Box
      textAlign={"left"}
      sx={{
        height: {
          xs: "auto",
          sm: "auto",
          md: "auto",
          lg: "82vh",
          xl: "82vh",
        },
        padding: {
          xs: "15px",
          sm: "15px",
          md: "15px",
          lg: "35px",
          xl: "35px",
        },
      }}
    >
      <form
        className="mb-0-mobile"
        onSubmit={formik.handleSubmit}
        style={{ overflow: "auto", maxHeight: "80vh", paddingBottom: "50px" }}
      >
        <label
          style={{ position: "relative" }}
          className="ulpoad-label"
          for="file-upload"
        >
          <img
            className="flie-upload-img"
            src={fileLink ? fileLink : ContactIcon}
            alt="upload-logo"
            style={{
              width: "140px",
              borderRadius: "50%",
              height: "140px",
            }}
          />
          <img
            className="camera-imgg"
            src={editPancilBgIcon}
            alt="upload-logo"
            style={{
              position: "absolute",
              right: "-15px",
              bottom: "15px",
              cursor: "pointer",
            }}
          />
        </label>
        <input
          type="file"
          id="file-upload"
          itemType=""
          style={{
            display: "none",
          }}
          onChange={(e) => handleFileUpload(e, "profilepic")}
          accept="image/*"
        />
        <InputLabel sx={{ ...LabelStyles, marginTop: "15px" }}>
          First and Last name
        </InputLabel>
        <Grid container columnSpacing={2}>
          <Grid xs={12} sm={12} md={12} lg={6} xl={6} item>
            <StyledInput
              name={"firstName"}
              placeholder={"Enter first name"}
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              errors={formik.errors}
              touched={formik.touched}
              value={formik.values.firstName}
            />
          </Grid>
          <Grid xs={12} sm={12} md={12} lg={6} xl={6} item>
            <StyledInput
              name={"lastName"}
              placeholder={"Enter last name"}
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              errors={formik.errors}
              touched={formik.touched}
              value={formik.values.lastName}
            />
          </Grid>
        </Grid>
        <Divider sx={{ marginBottom: "20px" }} />
        <Grid container columnSpacing={2}>
          <Grid xs={12} sm={12} md={12} lg={6} xl={6} item>
            <InputLabel sx={{ ...LabelStyles }}>Email Address</InputLabel>
            <StyledInput
              name={"email"}
              placeholder={"Enter email address"}
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              errors={formik.errors}
              touched={formik.touched}
              value={formik.values.email}
              disabled
            />
          </Grid>
          <Grid xs={12} sm={12} md={12} lg={6} xl={6} item>
            <InputLabel sx={{ ...LabelStyles }}>Phone number</InputLabel>
            <PhoneInput
              inputExtraProps={{
                placeholder: "Enter phone number",
                searchPlaceholder: "Enter phone number",
              }}
              disabled
              inputProps={{
                name: "mobileNo",
              }}
              value={formik.values?.countryCode + formik.values?.phone}
              renderStringAsFlag=""
              placeholder="Enter phone number"
              searchPlaceholder="Enter phone number"
              country="ca"
              enableAreaCodes={false}
              copyNumbersOnly={false}
              buttonStyle={{ background: "none", border: "none" }}
              inputStyle={{
                padding: "10px 5px 10px 40px",
                background: "#eff6f8",
                color: "#0d2645",
                width: "100%",
                height: "50px",
                borderRadius: "10px",
              }}
            />
          </Grid>
        </Grid>
        <Divider sx={{ marginBottom: "20px" }} />
        <InputLabel sx={{ ...LabelStyles }}>Bio </InputLabel>
        <TextareaAutosize
          name="bio"
          className="area-desc"
          placeholder="A brief bio highlights your unique skills and experiences."
          minRows={3}
          value={formik.values.bio}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          style={{
            padding: "10px 5px 10px 20px",
            background: "#eff6f8",
            width: "92%",
            border: "none",
            height: "30px",
            borderRadius: "20px",
            marginBottom: "20px",
            fontFamily: "Lato, sans-serif",
            fontSize: "15px",
          }}
        />

        <Grid container columnSpacing={2}>
          <Grid xs={12} sm={12} md={12} lg={6} xl={6} item>
            <InputLabel sx={{ ...LabelStyles }}>Years of Experience</InputLabel>
            <StyledInput
              name={"experience"}
              placeholder={"Enter Experience"}
              handleChange={(e) => {
                if (!e.target.value || /^\d+(\.\d)?$/.test(e.target.value)) {
                  formik.handleChange(e);
                }
              }}
              handleBlur={formik.handleBlur}
              errors={formik.errors}
              touched={formik.touched}
              value={formik.values.experience}
            />
          </Grid>
          <Grid xs={12} sm={12} md={12} lg={6} xl={6} item>
            <InputLabel sx={{ ...LabelStyles }}>
              Level of Care Experience
            </InputLabel>
            <Select
              name="experienceLevel"
              renderValue={(value) => (
                <Typography
                  sx={
                    value.includes("Select")
                      ? {
                          color: "#7b8c9d",
                          height: "30px",
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }
                      : {
                          height: "30px",
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }
                  }
                  fontWeight={600}
                  fontSize={16}
                  lineHeight={29}
                >
                  {value}
                </Typography>
              )}
              inputProps={{
                style: {
                  padding: "10px",
                  height: "50px",
                },
              }}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={
                experienceLevels[formik.values?.experienceLevel] ||
                "Select your level of care experience"
              }
              sx={{
                padding: "10px 5px 10px 0",
                background: "#eff6f8",
                color: "#0d2645",
                fontSize: "16px",
                width: "100%",
                height: "50px",
                textAlign: "left",
                borderRadius: "10px",
                "&:after": {
                  borderBottom: "none",
                },
                "&:before": {
                  borderBottom: "none",
                },
                "&:hover:not(.Mui-disabled, .Mui-error):before": {
                  borderBottom: "none",
                },
                "&.Mui-focused": {
                  border: "none",
                  outline: "none",
                },
              }}
            >
              <MenuItem
                value="med/sur"
                sx={{
                  height: "50px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  color: "#0d2645",
                  padding: "0 8px",
                  "&.Mui-selected": {
                    background: "none",
                  },
                }}
              >
                <Typography fontWeight={600} fontSize={16} lineHeight={29}>
                  Med/Sur
                </Typography>
                <Checkbox
                  sx={{
                    borderRadius: "50%",
                  }}
                  icon={<img src={tickCircle} alt="checked" />}
                  checkedIcon={<img src={tickCheckedCircle} alt="checked" />}
                  checked={formik.values.experienceLevel == "med/sur"}
                />
              </MenuItem>
              <Divider
                sx={{ marginTop: "0 !important", marginBottom: "0 !important" }}
              />
              <MenuItem
                value="telemetry"
                sx={{
                  height: "50px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  color: "#0d2645",
                  padding: "0 8px",
                  "&.Mui-selected": {
                    background: "none",
                  },
                }}
              >
                <Typography fontWeight={600} fontSize={16} lineHeight={29}>
                  Telemetry
                </Typography>
                <Checkbox
                  sx={{
                    borderRadius: "50%",
                  }}
                  icon={<img src={tickCircle} alt="checked" />}
                  checkedIcon={<img src={tickCheckedCircle} alt="checked" />}
                  checked={formik.values.experienceLevel == "telemetry"}
                />
              </MenuItem>
              <Divider
                sx={{ marginTop: "0 !important", marginBottom: "0 !important" }}
              />
              <MenuItem
                value="icu"
                sx={{
                  height: "50px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  color: "#0d2645",
                  padding: "0 8px",
                  "&.Mui-selected": {
                    background: "none",
                  },
                }}
              >
                <Typography fontWeight={600} fontSize={16} lineHeight={29}>
                  ICU
                </Typography>
                <Checkbox
                  sx={{
                    borderRadius: "50%",
                  }}
                  icon={<img src={tickCircle} alt="checked" />}
                  checkedIcon={<img src={tickCheckedCircle} alt="checked" />}
                  checked={formik.values.experienceLevel == "icu"}
                />
              </MenuItem>
              <Divider
                sx={{ marginTop: "0 !important", marginBottom: "0 !important" }}
              />
              <MenuItem
                value="ccu"
                sx={{
                  height: "50px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  color: "#0d2645",
                  padding: "0 8px",
                  "&.Mui-selected": {
                    background: "none",
                  },
                }}
              >
                <Typography fontWeight={600} fontSize={16} lineHeight={29}>
                  CCU
                </Typography>
                <Checkbox
                  sx={{
                    borderRadius: "50%",
                  }}
                  icon={<img src={tickCircle} alt="checked" />}
                  checkedIcon={<img src={tickCheckedCircle} alt="checked" />}
                  checked={formik.values.experienceLevel == "ccu"}
                />
              </MenuItem>
              <Divider
                sx={{ marginTop: "0 !important", marginBottom: "0 !important" }}
              />
              <MenuItem
                value="ed"
                sx={{
                  height: "50px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  color: "#0d2645",
                  padding: "0 8px",
                  "&.Mui-selected": {
                    background: "none",
                  },
                }}
              >
                <Typography fontWeight={600} fontSize={16} lineHeight={29}>
                  ED
                </Typography>
                <Checkbox
                  sx={{
                    borderRadius: "50%",
                  }}
                  icon={<img src={tickCircle} alt="checked" />}
                  checkedIcon={<img src={tickCheckedCircle} alt="checked" />}
                  checked={formik.values.experienceLevel == "ed"}
                />
              </MenuItem>
              <Divider
                sx={{ marginTop: "0 !important", marginBottom: "0 !important" }}
              />
              <MenuItem
                value="pacu"
                sx={{
                  height: "50px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  color: "#0d2645",
                  padding: "0 8px",
                  "&.Mui-selected": {
                    background: "none",
                  },
                }}
              >
                <Typography fontWeight={600} fontSize={16} lineHeight={29}>
                  PACU
                </Typography>
                <Checkbox
                  sx={{
                    borderRadius: "50%",
                  }}
                  icon={<img src={tickCircle} alt="checked" />}
                  checkedIcon={<img src={tickCheckedCircle} alt="checked" />}
                  checked={formik.values.experienceLevel == "pacu"}
                />
              </MenuItem>
            </Select>
          </Grid>
        </Grid>
        <Divider sx={{ marginBottom: "20px" }} />
        <Grid container columnSpacing={2}>
          <Grid
            xs={12}
            sm={12}
            md={12}
            lg={6}
            xl={6}
            sx={{
              position: "relative",
              marginBottom: {
                xs: "15px",
                sm: 0,
                md: 0,
                lg: 0,
                xl: 0,
              },
            }}
            item
          >
            <InputLabel sx={{ ...LabelStyles }}>Resume</InputLabel>
            <Document
              file={resume}
              options={{ workerSrc: "/pdf.worker.js" }}
              onSourceError={(err) => console.log(err)}
              onSourceSuccess={() => console.log("SUCCESS")}
              onLoadError={() => console.log("ERR")}
            >
              <Page
                pageNumber={1}
                scale={1}
                renderTextLayer={false}
                renderAnnotationLayer={false}
              ></Page>
            </Document>
            <label for="file-upload1">
              <img
                src={editPancilBgIcon}
                alt="upload-logo"
                style={{
                  position: "absolute",
                  right: 15,
                  bottom: 15,
                  cursor: "pointer",
                }}
              />
            </label>
            <input
              type="file"
              id="file-upload1"
              itemType=""
              style={{
                display: "none",
              }}
              onChange={(e) => handleFileUpload(e, "resume")}
            />
          </Grid>
          <Grid
            xs={12}
            sm={12}
            md={12}
            lg={6}
            xl={6}
            sx={{ position: "relative" }}
            item
          >
            <InputLabel sx={{ ...LabelStyles }}>License</InputLabel>
            <Document
              file={license}
              options={{ workerSrc: "/pdf.worker.js" }}
              onSourceError={(err) => console.log(err)}
              onSourceSuccess={() => console.log("SUCCESS")}
              onLoadError={() => console.log("ERR")}
            >
              <Page
                pageNumber={1}
                scale={1}
                renderTextLayer={false}
                renderAnnotationLayer={false}
              ></Page>
            </Document>
            <label for="file-upload2">
              <img
                src={editPancilBgIcon}
                alt="upload-logo"
                style={{
                  position: "absolute",
                  right: 15,
                  bottom: 15,
                  cursor: "pointer",
                }}
              />
            </label>
            <input
              type="file"
              id="file-upload2"
              itemType=""
              style={{
                display: "none",
              }}
              onChange={(e) => handleFileUpload(e, "license")}
            />
          </Grid>
        </Grid>
        <Divider sx={{ marginTop: "20px", marginBottom: "20px" }} />
        <Grid container columnSpacing={2}>
          <Grid xs={12} sm={12} md={12} lg={6} xl={6} item>
            <Select
              name="licensedStates"
              placeholder="Select States"
              multiple
              renderValue={(values) => (
                <Typography sx={{ color: "#7b8c9d" }}>Select States</Typography>
              )}
              inputProps={{
                style: {
                  padding: "10px",
                },
              }}
              value={licensedStates || []}
              sx={{
                padding: "10px 5px 10px 0",
                background: "#eff6f8",
                color: "#0d2645",
                fontSize: "16px",
                width: "100%",
                height: "50px",
                textAlign: "left",
                borderRadius: "10px",
                "&:after": {
                  borderBottom: "none",
                },
                "&:before": {
                  borderBottom: "none",
                },
                "&:hover:not(.Mui-disabled, .Mui-error):before": {
                  borderBottom: "none",
                },
                "&.Mui-focused": {
                  border: "none",
                  outline: "none",
                },
              }}
              onChange={(e) => {
                setStateChanged(true);
                setLicensedStates(e.target.value);
              }}
            >
              {usaStates.map((stateObj) => {
                return (
                  <MenuItem
                    value={stateObj?.name}
                    key={stateObj?.code}
                    sx={{
                      height: "50px",
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      color: "#0d2645",
                      padding: "0 8px",
                      "&.Mui-selected": {
                        background: "none",
                      },
                    }}
                  >
                    <Typography fontWeight={600} fontSize={16} lineHeight={29}>
                      {stateObj?.name}
                    </Typography>
                    <Checkbox
                      sx={{
                        borderRadius: "50%",
                      }}
                      icon={<img src={tickCircle} alt="checked" />}
                      checkedIcon={
                        <img src={tickCheckedCircle} alt="checked" />
                      }
                      checked={licensedStates?.includes(stateObj?.name)}
                    />
                  </MenuItem>
                );
              })}
            </Select>
            <Box
              sx={{
                marginTop: "20px",
              }}
            >
              {licensedStates
                ?.filter((item) => item != "Select States")
                ?.map((i) => {
                  return (
                    <Chip
                      key={i}
                      sx={{
                        bgcolor: "#38879f",
                        margin: "10px",
                        padding: "10px 5px",
                        borderRadius: "10px",
                      }}
                      label={
                        <Box>
                          <Typography color="#fff">
                            {i}&nbsp;
                            <span
                              style={{ marginLeft: "5px", cursor: "pointer" }}
                              onClick={() => handleChangeStates(i)}
                            >
                              X
                            </span>
                          </Typography>
                        </Box>
                      }
                    />
                  );
                })}
            </Box>
          </Grid>
        </Grid>
        <Button
          type="submit"
          disabled={
            formik.isSubmitting ||
            Object.entries(formik.errors).length > 0 ||
            Object.keys(formik.touched)?.length === 0
              ? !isStateChanged
              : Object.keys(formik.touched)?.length === 0
          }
          sx={{
            width: {
              xs: "100%",
              sm: "50%",
              md: "40%",
              lg: "20%",
              xl: "15%",
            },
            height: "55px",
            marginTop: "15px",
            background: "#38879f",
            color: "#fff",
            fontWeight: 600,
            borderRadius: "10px",
            "&.Mui-disabled": {
              background: "#adcdd7",
              color: "#fff",
            },
            "&:hover": {
              backgroundColor: "#38879f",
            },
          }}
        >
          Save
        </Button>
      </form>
    </Box>
  );
};

const ChangePasswordSettings = () => {
  const [passwordVisibility, setPasswordVisibility] = useState("");
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      password: "",
      newPassword: "",
      confirmPassword: "",
    },
    validate: (values) => {
      const passRegex = RegExp("^.{8,15}$");
      const errors = {};
      if (!values.password) {
        errors.password = "Required";
      }
      if (!passRegex.test(values.newPassword)) {
        errors.newPassword = "Must contain at least 8 or more characters";
      }
      if (!values.newPassword) {
        errors.newPassword = "Required";
      }
      if (!values.confirmPassword) {
        errors.confirmPassword = "Required";
      }
      if (values.newPassword !== values.confirmPassword) {
        errors.confirmPassword =
          "New password and Confirm password should match.";
      }
      return errors;
    },
    onSubmit: (values) => handleSubmit(values),
  });
  const handleSubmit = (values) => {
    dispatch(setLoading(true));
    const payload = {
      oldPassword: values.password,
      newPassword: values.newPassword,
    };
    changePassword(payload)
      .then((resp) => {
        formik.resetForm();
        setSuccessModalVisible(true);
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        dispatch(setLoading(false));
        formik.setSubmitting(false);
      });
  };
  return (
    <Box
      sx={{
        padding: {
          xs: "15px",
          sm: "15px",
          md: "15px",
          lg: "20px 30px",
          xl: "20px 30px",
        },
        width: {
          xs: "calc(100% - 30px)",
          sm: "calc(100% - 30px)",
          md: "calc(100% - 30px)",
          lg: "40%",
          xl: "40%",
        },
      }}
    >
      <form onSubmit={formik.handleSubmit}>
        <Box marginBottom={"20px"}>
          <Input
            type={passwordVisibility == "password" ? "text" : "password"}
            name="password"
            placeholder="Enter password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
            endAdornment={
              <InputAdornment
                position="end"
                sx={{ paddingRight: "10px", cursor: "pointer" }}
              >
                {passwordVisibility == "password" ? (
                  <img
                    src={EyeIcon}
                    onClick={() => setPasswordVisibility("")}
                  />
                ) : (
                  <img
                    src={EyeSlashIcon}
                    onClick={() => setPasswordVisibility("password")}
                  />
                )}
              </InputAdornment>
            }
            inputProps={{
              style: {
                padding: "10px",
              },
            }}
            sx={{
              padding: "10px 5px 10px 20px",
              background: "#eff6f8",
              width: "100%",
              height: "50px",
              borderRadius: "10px",
              "&:after": {
                borderBottom: "none",
              },
              "&:before": {
                borderBottom: "none",
              },
              "&:hover:not(.Mui-disabled, .Mui-error):before": {
                borderBottom: "none",
              },
              "&.Mui-focused": {
                borderBottom: "none",
              },
            }}
          />
          {formik.errors.password && formik.touched.password && (
            <Typography textAlign={"left"} variant="body2" color="red">
              {formik.errors.password}
            </Typography>
          )}
        </Box>
        <Box marginBottom={"20px"}>
          <Input
            type={passwordVisibility == "newPassword" ? "text" : "password"}
            name="newPassword"
            placeholder="Enter new password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.newPassword}
            endAdornment={
              <InputAdornment
                position="end"
                sx={{ paddingRight: "10px", cursor: "pointer" }}
              >
                {passwordVisibility === "newPassword" ? (
                  <img
                    src={EyeIcon}
                    onClick={() => setPasswordVisibility("")}
                  />
                ) : (
                  <img
                    src={EyeSlashIcon}
                    onClick={() => setPasswordVisibility("newPassword")}
                  />
                )}
              </InputAdornment>
            }
            inputProps={{
              style: {
                padding: "10px",
              },
            }}
            sx={{
              padding: "10px 5px 10px 20px",
              background: "#eff6f8",
              width: "100%",
              height: "50px",
              borderRadius: "10px",
              "&:after": {
                borderBottom: "none",
              },
              "&:before": {
                borderBottom: "none",
              },
              "&:hover:not(.Mui-disabled, .Mui-error):before": {
                borderBottom: "none",
              },
              "&.Mui-focused": {
                borderBottom: "none",
              },
            }}
          />
          {formik.errors.newPassword && formik.touched.newPassword && (
            <Typography textAlign={"left"} variant="body2" color="red">
              {formik.errors.newPassword}
            </Typography>
          )}
        </Box>
        <Box>
          <Input
            type={passwordVisibility == "confirmPassword" ? "text" : "password"}
            name="confirmPassword"
            placeholder="Enter confirm password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.confirmPassword}
            endAdornment={
              <InputAdornment
                position="end"
                sx={{ paddingRight: "10px", cursor: "pointer" }}
              >
                {passwordVisibility === "confirmPassword" ? (
                  <img
                    src={EyeIcon}
                    onClick={() => setPasswordVisibility("")}
                  />
                ) : (
                  <img
                    src={EyeSlashIcon}
                    onClick={() => setPasswordVisibility("confirmPassword")}
                  />
                )}
              </InputAdornment>
            }
            inputProps={{
              style: {
                padding: "10px",
              },
            }}
            sx={{
              padding: "10px 5px 10px 20px",
              background: "#eff6f8",
              width: "100%",
              height: "50px",
              borderRadius: "10px",
              "&:after": {
                borderBottom: "none",
              },
              "&:before": {
                borderBottom: "none",
              },
              "&:hover:not(.Mui-disabled, .Mui-error):before": {
                borderBottom: "none",
              },
              "&.Mui-focused": {
                borderBottom: "none",
              },
            }}
          />
          {formik.errors.confirmPassword && formik.touched.confirmPassword && (
            <Typography textAlign={"left"} variant="body2" color="red">
              {formik.errors.confirmPassword}
            </Typography>
          )}
        </Box>
        <Box textAlign={"left"}>
          <Button
            type="submit"
            disabled={
              formik.isSubmitting ||
              Object.entries(formik.errors).length > 0 ||
              Object.values(formik.values).every((i) => !i)
            }
            sx={{
              width: "35%",
              height: "55px",
              marginTop: "20px",
              background: "#38879f",
              color: "#fff",
              fontWeight: 600,
              borderRadius: "10px",
              "&.Mui-disabled": {
                background: "#adcdd7",
                color: "#fff",
              },
              "&:hover": {
                backgroundColor: "#38879f",
              },
            }}
          >
            Save
          </Button>
        </Box>
      </form>
      {/* )} */}
      {/* </Formik> */}
      <CommonModal
        title="Password Changed Successfully"
        open={successModalVisible}
        icon={TickCardIcon}
        onClose={() => setSuccessModalVisible(false)}
        isSingleButton
        singleBtnText={"Done"}
      />
    </Box>
  );
};

const ContactUs = () => {
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const userData = useSelector((state) => state.user.userData);
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      name: userData?.firstName + " " + userData?.lastName,
      email: userData?.email,
      subject: "",
      message: "",
    },
    validationSchema: object({
      name: string().required("This field is required"),
      subject: string().required("This field is required"),
      email: string().required("This field is required").email(),
      message: string().required("This field is required"),
    }),
    onSubmit: (values) => handleSubmit(values),
  });
  const handleSubmit = (values) => {
    dispatch(setLoading(true));
    const payload = { ...values };
    SendSupportQuery(payload)
      .then((resp) => {
        formik.setSubmitting(false);
        setSuccessModalVisible(true);
      })
      .catch((Err) => {
        toast.error(Err.message);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
  return (
    <Box padding={"20px 30px"} textAlign={"left"}>
      <form onSubmit={formik.handleSubmit}>
        <Grid container columnSpacing={2}>
          <Grid xs={12} sm={12} lg={6} xl={6} md={12} item>
            <InputLabel sx={{ ...LabelStyles }}>Name </InputLabel>
            <StyledInput
              name={"name"}
              placeholder={"Enter name"}
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              errors={formik.errors}
              touched={formik.touched}
              value={formik.values.name}
            />
          </Grid>
          <Grid xs={12} sm={12} lg={6} xl={6} md={12} item>
            <InputLabel sx={{ ...LabelStyles }}>E-mail </InputLabel>
            <StyledInput
              name={"email"}
              placeholder={"Enter email"}
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              errors={formik.errors}
              touched={formik.touched}
              value={formik.values.email}
            />
          </Grid>
          <Grid
            className="w-100-input"
            xs={12}
            sm={12}
            lg={6}
            xl={6}
            md={12}
            item
          >
            <InputLabel sx={{ ...LabelStyles }}>Subject </InputLabel>
            <StyledInput
              name={"subject"}
              placeholder={"Enter subject"}
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              errors={formik.errors}
              touched={formik.touched}
              value={formik.values.subject}
              style={{ width: "100%" }}
            />
          </Grid>
        </Grid>
        <InputLabel sx={{ ...LabelStyles }}>Message </InputLabel>
        <TextareaAutosize
          name="message"
          className="area-desc"
          placeholder="Enter message"
          minRows={5}
          value={formik.values.message}
          onChange={formik.handleChange}
          style={{
            padding: "10px 5px 10px 20px",
            background: "#eff6f8",
            width: "92%",
            border: "none",
            height: "30px",
            borderRadius: "20px",
            marginBottom: "20px",
            fontFamily: "Lato, sans-serif",
            fontSize: "15px",
          }}
        />
        <Button
          type="submit"
          disabled={
            formik.isSubmitting || Object.entries(formik.errors).length > 0
          }
          sx={{
            width: "170px",
            height: "55px",
            marginTop: "5px",
            background: "#38879f",
            color: "#fff",
            fontWeight: 600,
            borderRadius: "10px",
            "&.Mui-disabled": {
              background: "#adcdd7",
              color: "#fff",
            },
            "&:hover": {
              backgroundColor: "#38879f",
            },
          }}
        >
          Send Query
        </Button>
      </form>
      <CommonModal
        title="Query sent 
        successfully"
        open={successModalVisible}
        icon={TickCardIcon}
        onClose={() => setSuccessModalVisible(false)}
        isSingleButton
        singleBtnText={"Done"}
      />
    </Box>
  );
};
