import Layout from "../../layout";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import { Box, Button, Input, InputAdornment, Typography } from "@mui/material";
import EmailIcon from "../../../../assets/icons/email.svg";
import PasswordIcon from "../../../../assets/icons/password.svg";
import EyeIcon from "../../../../assets/icons/eye.svg";
import EyeSlashIcon from "../../../../assets/icons/eye-slash.svg";
import { useNavigate } from "react-router-dom";
import { userSignIn } from "../../../../controller/services/auth";
import toast from "react-hot-toast";
import { setUserData } from "../../../../redux/slices/user_slice";
import { useDispatch } from "react-redux";
import {
  setActiveSelectedTab,
  setHospitalMenuSelected,
  setNotificationDot,
  setNurseMenuSelected,
} from "../../../../redux/slices/flags_slice";

export default function Signin() {
  const [passwordVisibility, setPasswordVisibility] = useState("");
  const [currUserType, setCurrUserType] = useState("hospital");
  let userType = localStorage.getItem("userType") || currUserType;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    if (window.location.href.includes("hospital")) {
      localStorage.setItem("userType", "hospital");
      setCurrUserType("hospital");
    } else {
      localStorage.setItem("userType", "nurse");
      setCurrUserType("nurse");
    }
  }, [window.location.href]);
  const handleSignin = (values) => {
    const fcmToken = localStorage.getItem("fcmToken");
    const payload = {
      userType: userType,
      email: values.email,
      password: values.password,
      fcmToken: fcmToken || "",
    };
    userSignIn(payload)
      .then((resp) => {
        localStorage.setItem("userData", JSON.stringify(resp.data));
        dispatch(setUserData(resp.data?.profile));
        dispatch(setNotificationDot(resp.data?.unreadNotificationCount > 0));
        toast.success(resp.message);
        const userData = resp.data?.profile;
        if (userData.userType == "nurse") {
          if (userData?.isProfileCreated && userData?.isPhoneVerified) {
            navigate("/nurse/dashboard");
          } else {
            navigate("/nurse/create-profile");
          }
        } else {
          if (userData?.isProfileCreated && userData?.isPhoneVerified) {
            if (userData?.userType == "hospital") {
              dispatch(setHospitalMenuSelected(0));
            } else {
              dispatch(setNurseMenuSelected(0));
            }
            if (userData?.isAccountVerified) {
              navigate("/hospital/dashboard");
              dispatch(setActiveSelectedTab(0));
            } else {
              navigate("/hospital/approval-status");
            }
          } else {
            navigate("/hospital/create-profile");
          }
        }
      })
      .catch((Err) => {
        toast.error(Err.message);
      });
  };
  return (
    <Layout isSignIn>
      <Box>
        <Formik
          initialValues={{ email: "", password: "" }}
          validate={(values) => {
            const passRegex = RegExp("^.{8,15}$");
            const errors = {};
            if (!values.email) {
              errors.email = "Required";
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = "Invalid email address";
            }
            if (!values.password) {
              errors.password = "Required";
            }
            if (!passRegex.test(values.password)) {
              errors.password = "Must contain at least 8 or more characters";
            }
            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
            handleSignin(values);
            setSubmitting(false);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit}>
              <Box marginBottom={"20px"}>
                <Input
                  type="email"
                  name="email"
                  placeholder="Enter email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  startAdornment={<img src={EmailIcon} alt="email" />}
                  inputProps={{
                    style: {
                      padding: "10px",
                    },
                  }}
                  sx={{
                    padding: "10px 5px 10px 20px",
                    background: "#eff6f8",
                    width: "100%",
                    height: "50px",
                    borderRadius: "10px",
                    "&:after": {
                      borderBottom: "none",
                    },
                    "&:before": {
                      borderBottom: "none",
                    },
                    "&:hover:not(.Mui-disabled, .Mui-error):before": {
                      borderBottom: "none",
                    },
                    "&.Mui-focused": {
                      borderBottom: "none",
                    },
                  }}
                />
                {errors.email && touched.email && (
                  <Typography textAlign={"left"} variant="body2" color="red">
                    {errors.email}
                  </Typography>
                )}
              </Box>
              <Box marginBottom={"20px"}>
                <Input
                  type={passwordVisibility !== "password" ? "password" : "text"}
                  name="password"
                  placeholder="Enter Password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  startAdornment={<img src={PasswordIcon} alt="password" />}
                  endAdornment={
                    <InputAdornment
                      position="end"
                      sx={{ paddingRight: "10px" }}
                    >
                      {passwordVisibility == "password" ? (
                        <img
                          src={EyeIcon}
                          onClick={() => setPasswordVisibility("")}
                          style={{ cursor: "pointer" }}
                        />
                      ) : (
                        <img
                          src={EyeSlashIcon}
                          onClick={() => setPasswordVisibility("password")}
                          style={{ cursor: "pointer" }}
                        />
                      )}
                    </InputAdornment>
                  }
                  inputProps={{
                    style: {
                      padding: "10px",
                    },
                  }}
                  sx={{
                    padding: "10px 5px 10px 20px",
                    background: "#eff6f8",
                    width: "100%",
                    height: "50px",
                    borderRadius: "10px",
                    "&:after": {
                      borderBottom: "none",
                    },
                    "&:before": {
                      borderBottom: "none",
                    },
                    "&:hover:not(.Mui-disabled, .Mui-error):before": {
                      borderBottom: "none",
                    },
                    "&.Mui-focused": {
                      borderBottom: "none",
                    },
                  }}
                />
                {errors.password && touched.password && (
                  <Typography textAlign={"left"} variant="body2" color="red">
                    {errors.password}
                  </Typography>
                )}
              </Box>
              <Box>
                <Typography
                  fontSize={"12.5px"}
                  color="#0d2645"
                  fontWeight={600}
                  sx={{
                    textDecoration: "underline",
                    textAlign: "right",
                    cursor: "pointer",
                  }}
                  onClick={() => navigate("/forgot-password")}
                >
                  Forgot Password?
                </Typography>
              </Box>
              <Button
                type="submit"
                disabled={
                  isSubmitting ||
                  Object.entries(errors).length > 0 ||
                  Object.values(values).every((i) => !i)
                }
                sx={{
                  width: "100%",
                  height: "55px",
                  marginTop: "15px",
                  background: "#38879f",
                  color: "#fff",
                  fontWeight: 600,
                  borderRadius: "10px",
                  "&.Mui-disabled": {
                    background: "#adcdd7",
                    color: "#fff",
                  },
                  "&:hover": {
                    backgroundColor: "#38879f",
                  },
                }}
              >
                Sign In
              </Button>
              <Typography fontSize={"14px"} color="#8592a1" marginTop={"25px"}>
                Don't have an account?{" "}
                <span
                  style={{
                    fontWeight: 600,
                    color: "#38879f",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (userType === "hospital") {
                      navigate("/hospital/signup");
                    } else {
                      navigate("/nurse/signup");
                    }
                  }}
                >
                  Sign Up
                </span>
              </Typography>
            </form>
          )}
        </Formik>
      </Box>
    </Layout>
  );
}
