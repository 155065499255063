import {
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Divider,
  LinearProgress,
  ListItem,
  ListItemButton,
  ListItemText,
  MenuItem,
  MobileStepper,
  Select,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import AppLogo from "../../../assets/icons/app-logo.svg";
import { useNavigate } from "react-router-dom";
import BackIcon from "../../../assets/icons/back-dark.svg";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import StyledInput from "../../common/styled/input";
import { number, object, string } from "yup";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import toast from "react-hot-toast";
import {
  createProfile,
  getProfile,
  sendPhoneVerification,
  verifyPhoneOtp,
} from "../../../controller/services/hospital/profile";
import OTPInput from "react-otp-input";
import "./index.css";
import useGoogle from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import {
  formatBytes,
  getAddressObject,
  usaStates,
} from "../../../utils/helper";
import ContactIcon from "../../../assets/icons/contact.svg";
import CameraIcon from "../../../assets/icons/camera.svg";
import {
  DeleteUploadedFile,
  FileUpload,
} from "../../../controller/services/common";
import tickCircle from "../../../assets/icons/tick-circle.svg";
import tickCheckedCircle from "../../../assets/icons/checked-circle.svg";
import UploadDocIcon from "../../../assets/icons/upload-doc.svg";
import FileIcon from "../../../assets/icons/file.svg";
import CloseIcon from "../../../assets/icons/close.svg";
import DocIcon from "../../../assets/icons/doc.svg";
import { useDispatch } from "react-redux";
import { setUserData } from "../../../redux/slices/user_slice";
import { setNotificationDot } from "../../../redux/slices/flags_slice";

export const experienceLevels = {
  telemetry: "Telemetry",
  ed: "ED",
  pacu: "PACU",
  "med/sur": "Med/Sur",
  icu: "ICU",
  ccu: "CCU",
};

export default function NurseProfileLayout() {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(0);
  const [currentValues, setCurrentValues] = useState({});
  console.log(currentValues, "currentValues");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const stepper = [
    {
      step: 0,
      component: InputName,
      title: "Enter first and last name",
    },
    {
      step: 1,
      component: PhoneInputComp,
      title: "Enter your phone number",
    },
    {
      step: 2,
      component: OtpVerification,
      title: "Verify your Phone Number",
      desc: "We have sent a verification code to your phone number. Please enter the code below",
    },
    {
      step: 3,
      component: LogoUpload,
      title:
        "A friendly, approachable photo helps you stand out and connect with hospitals and colleagues.",
      desc: "",
    },
    {
      step: 4,
      component: HospitalDescription,
      title: "Enter your bio",
    },
    {
      step: 5,
      component: Experience,
      title: "Years of Experience",
    },
    {
      step: 6,
      component: LevelCareExp,
      title: "Level of Care Experience",
    },
    {
      step: 7,
      component: UploadResume,
      title: "Upload your resume",
      desc: "Enhance your profile by uploading your résumé!",
    },
    {
      step: 8,
      component: UploadLicense,
      title: "Upload Your Professional License",
      desc: "",
    },
    {
      step: 9,
      component: SelectLicensedStates,
      title: "In what states are you currently licensed ?",
      desc: "",
    },
  ];
  const Item = stepper.find((i) => i.step === currentStep);

  const handleCurrentStep = (values, IS_FINAL) => {
    setCurrentValues((prev) => {
      return { ...prev, ...values };
    });
    if (IS_FINAL) {
      setLoading(true);
      const payload = {
        firstName: currentValues.firstName,
        lastName: currentValues.lastName,
        profilePicture: currentValues.profilePicture,
        bio: currentValues.bio,
        experience: currentValues.experience,
        experienceLevel: currentValues.experienceLevel,
        resume: currentValues?.resume || "",
        license: currentValues?.license || "",
        licensedStates: values.licensedStates,
      };
      createProfile(payload)
        .then((resp) => {
          navigate("/nurse/account-created");
          toast.success(resp.message);
          getProfileData();
        })
        .catch((Err) => {
          toast.error(Err.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
    setCurrentStep((prev) => prev + 1);
  };

  const getProfileData = () => {
    getProfile()
      .then((resp) => {
        dispatch(setUserData(resp.data?.user));
        dispatch(setNotificationDot(resp.data?.unreadNotificationCount > 0));
      })
      .catch((Err) => {
        toast.error(Err.message);
      });
  };

  const handleSkip = () => {
    if (currentStep === 8) {
      handleCurrentStep({ license: "" });
    } else if (currentStep == 7) {
      handleCurrentStep({ resume: "" });
    }
  };
  return (
    <Box
      className="custom-stepper-div"
      sx={{
        padding: {
          xs: "30px 15px",
          sm: "0 15px",
          md: "0 60px",
          lg: "0 60px",
          xl: "0 70px",
        },
        height: {
          xs: "auto",
          sm: "100vh",
          md: "100vh",
          lg: "100vh",
          xl: "100vh",
        },
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {loading ? (
        <CircularProgress />
      ) : (
        <Box
          position="relative"
          width={"100%"}
          display="flex"
          alignItems="center"
          justifyContent="center"
          border={2}
          borderColor={"#38879F"}
          borderRadius={5}
        >
          <Box
            sx={{
              padding: {
                xs: "30px 20px",
                sm: "30px",
                md: "30px",
                lg: "30px",
                xl: "45px",
              },
              width: {
                xs: "100%",
                sm: "100%",
                md: "70%",
                lg: "35%",
                xl: "35%",
              },
            }}
          >
            {currentStep != 0 && (
              <img
                src={BackIcon}
                alt="back"
                style={{
                  position: "absolute",
                  left: "8vw",
                  top: "8vh",
                  cursor: "pointer",
                }}
                className="back-icon"
                onClick={() => setCurrentStep((prev) => prev - 1)}
              />
            )}
            <img className="app-logo-role" src={AppLogo} alt="logo" />
            <MobileStepper
              color="#38879f"
              variant="progress"
              steps={10}
              position="static"
              activeStep={currentStep}
              LinearProgressProps={{
                style: {
                  background: "#eaf5f2",
                  borderRadius: "25px",
                  height: "6px",
                },
              }}
              sx={{
                width: {
                  xs: "100%",
                  sm: "100%",
                  md: "100%",
                  lg: "100%",
                  xl: "100%",
                },
                marginTop: "10px",
                padding: "20px 0",
                borderRadius: "25px",
                height: "6px",
                "& .MuiLinearProgress-bar": {
                  background: "#38879f",
                  borderRadius: "25px",
                },
              }}
            />

            {currentStep <= 9 && (
              <>
                <Typography
                  variant="h3"
                  color="#0d2645"
                  sx={{
                    fontSize: {
                      xs: "20px",
                      sm: "24px",
                      md: "24px",
                      lg: "24px",
                      xl: "24px",
                    },
                  }}
                  fontWeight={600}
                  lineHeight={"31.2px"}
                  margin={Item?.desc ? "10px" : "20px 10px"}
                >
                  {Item.title}
                </Typography>
                <Typography
                  variant="h6"
                  color="#8391a0"
                  fontSize={16}
                  fontWeight={400}
                  lineHeight={"25px"}
                  margin={currentStep == 7 ? "20px" : "10px"}
                >
                  {Item?.desc}
                </Typography>
                <Item.component
                  handleCurrentStep={handleCurrentStep}
                  currentValues={currentValues}
                />
              </>
            )}
            {(currentStep === 8 || currentStep === 7) && (
              <Button
                variant="outlined"
                sx={{
                  height: "55px",
                  color: "#38879f",
                  marginTop: "10px",
                  width: "100%",
                  border: "1px solid #38879f",
                }}
                onClick={handleSkip}
              >
                Skip
              </Button>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
}

const InputName = ({ handleCurrentStep, currentValues }) => {
  const formik = useFormik({
    initialValues: {
      firstName: currentValues?.firstName,
      lastName: currentValues?.lastName,
    },
    validationSchema: object({
      firstName: string()
        .required("This field is required")
        .matches(/^[A-Za-z ]*$/, "Please enter valid name")
        .min(3, "First Name must be at least 3 characters")
        .max(15, "First Name must be at most 15 characters"),
      lastName: string()
        .required("This field is required")
        .matches(/^[A-Za-z ]*$/, "Please enter valid name")
        .min(3, "Last Name must be at least 3 characters")
        .max(15, "Last Name must be at most 15 characters"),
    }),
    onSubmit: (values) => handleSubmit(values),
  });

  const handleSubmit = (values) => {
    handleCurrentStep(values);
  };
  return (
    <form onSubmit={formik.handleSubmit}>
      <StyledInput
        name={"firstName"}
        placeholder={"Enter First Name"}
        handleChange={formik.handleChange}
        handleBlur={formik.handleBlur}
        errors={formik.errors}
        touched={formik.touched}
        value={formik.values.firstName}
      />
      <StyledInput
        name={"lastName"}
        placeholder={"Enter Last Name"}
        handleChange={formik.handleChange}
        handleBlur={formik.handleBlur}
        errors={formik.errors}
        touched={formik.touched}
        value={formik.values.lastName}
      />
      <Button
        type="submit"
        disabled={
          formik.isSubmitting ||
          Object.entries(formik.errors).length > 0 ||
          Object.values(formik.values).every((i) => !i)
        }
        sx={{
          width: "100%",
          height: "55px",
          marginTop: "15px",
          background: "#38879f",
          color: "#fff",
          fontWeight: 600,
          borderRadius: "10px",
          "&.Mui-disabled": {
            background: "#adcdd7",
            color: "#fff",
          },
          "&:hover": {
            backgroundColor: "#38879f",
          },
        }}
      >
        Next
      </Button>
    </form>
  );
};

const PhoneInputComp = ({ handleCurrentStep, currentValues }) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCode, setCountryCode] = useState("");

  useEffect(() => {
    if (currentValues?.phoneNumber) {
      setPhoneNumber(currentValues?.phoneNumber);
      setCountryCode(currentValues?.countryCode);
    }
  }, [currentValues?.phoneNumber]);
  const handleSubmit = () => {
    const payload = {
      countryCode,
      phone: phoneNumber,
    };
    sendPhoneVerification(payload)
      .then((resp) => {
        handleCurrentStep({ phoneNumber, countryCode });
        toast.success(resp.message);
      })
      .catch((Err) => {
        toast.error(Err.message);
      });
  };
  return (
    <Box>
      <PhoneInput
        inputExtraProps={{
          placeholder: "Enter phone number",
          searchPlaceholder: "Enter phone number",
        }}
        inputProps={{
          name: "mobileNo",
        }}
        value={countryCode + phoneNumber}
        renderStringAsFlag=""
        placeholder="Enter phone number"
        searchPlaceholder="Enter phone number"
        country="ca"
        enableAreaCodes={false}
        copyNumbersOnly={false}
        buttonStyle={{ background: "none", border: "none" }}
        inputStyle={{
          padding: "10px 5px 10px 40px",
          background: "#eff6f8",
          color: "#0d2645",
          width: "100%",
          height: "50px",
          borderRadius: "10px",
        }}
        onChange={(value, country, e, formattedValue) => {
          setCountryCode(country?.dialCode);
          setPhoneNumber(value.slice(country?.dialCode?.length));
        }}
      />
      <Button
        onClick={handleSubmit}
        disabled={phoneNumber?.length < 10}
        sx={{
          width: "100%",
          height: "55px",
          marginTop: "15px",
          background: "#38879f",
          color: "#fff",
          fontWeight: 600,
          borderRadius: "10px",
          "&.Mui-disabled": {
            background: "#adcdd7",
            color: "#fff",
          },
          "&:hover": {
            backgroundColor: "#38879f",
          },
        }}
      >
        Next
      </Button>
    </Box>
  );
};

const OtpVerification = ({ handleCurrentStep, currentValues }) => {
  const [otp, setOtp] = useState("");
  const [timer, setTimer] = useState(30);
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    if (otp?.length === 6) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [otp?.length]);
  const handleSubmit = () => {
    setDisabled(true);
    const payload = { otp };
    verifyPhoneOtp(payload)
      .then((resp) => {
        handleCurrentStep({});
        toast.success(resp.message);
      })
      .catch((Err) => {
        setDisabled(true);
        toast.error(Err.message);
      });
  };

  useEffect(() => {
    const intervalID = setInterval(() => {
      if (timer != 0) {
        setTimer((prev) => prev - 1);
      } else setTimer(0);
    }, 1000);
    return () => {
      clearInterval(intervalID);
    };
  }, [timer]);

  const handleResend = () => {
    const payload = {
      countryCode: currentValues?.countryCode,
      phone: currentValues?.phoneNumber,
    };
    sendPhoneVerification(payload)
      .then((resp) => {
        setTimer(30);
        toast.success(resp.message);
      })
      .catch((Err) => {
        toast.error(Err.message);
      });
  };
  return (
    <Box className="custom-otp-input">
      <OTPInput
        value={otp}
        onChange={setOtp}
        numInputs={6}
        renderInput={(props) => <input {...props} />}
        inputStyle={{
          background: "#ebf3f6",
          width: "50px",
          height: "50px",
          borderRadius: "15px",
          color: "#0d2645",
          textAlign: "center",
          border: "none",
          fontSize: "15px",
          outline: "none",
          margin: "0 8px",
        }}
        placeholder="------"
        containerStyle={{
          justifyContent: "center",
          marginTop: "25px",
        }}
      />

      <Typography
        fontSize={"14px"}
        color="#8592a1"
        marginTop={"15px"}
        marginBottom={"10px"}
        sx={
          timer != 0
            ? {
                opacity: 0.7,
              }
            : {}
        }
      >
        Have you received the code? &nbsp;
        <span
          style={{
            fontWeight: 600,
            color: "#38879f",
            cursor: timer == 0 ? "pointer" : "default",
          }}
          onClick={() => {
            if (timer == 0) {
              handleResend();
            }
          }}
        >
          Resend
        </span>
        <br />
        {timer != 0 && (
          <span style={{ color: "#0d2645", marginTop: "10px", opacity: 1 }}>
            00:{timer}
          </span>
        )}
      </Typography>
      <Button
        onClick={handleSubmit}
        sx={{
          width: "80%",
          height: "55px",
          marginTop: "15px",
          background: "#38879f",
          color: "#fff",
          fontWeight: 600,
          borderRadius: "10px",
          "&.Mui-disabled": {
            background: "#adcdd7",
            color: "#fff",
          },
          "&:hover": {
            backgroundColor: "#38879f",
          },
        }}
        disabled={disabled}
      >
        Verify
      </Button>
    </Box>
  );
};

const HospitalName = ({ handleCurrentStep }) => {
  const formik = useFormik({
    initialValues: {
      hospitalName: "",
    },
    validationSchema: object({
      hospitalName: string().required("This field is required"),
    }),
    onSubmit: (values) => handleSubmit(values),
  });

  const handleSubmit = (values) => {
    handleCurrentStep(values);
  };
  return (
    <form onSubmit={formik.handleSubmit}>
      <StyledInput
        name={"hospitalName"}
        placeholder={"Enter Hospital Name"}
        handleChange={formik.handleChange}
        handleBlur={formik.handleBlur}
        errors={formik.errors}
        touched={formik.touched}
        value={formik.values.hospitalName}
      />
      <Button
        type="submit"
        disabled={
          formik.isSubmitting || Object.entries(formik.errors).length > 0
        }
        sx={{
          width: "100%",
          height: "55px",
          marginTop: "15px",
          background: "#38879f",
          color: "#fff",
          fontWeight: 600,
          borderRadius: "10px",
          "&.Mui-disabled": {
            background: "#adcdd7",
            color: "#fff",
          },
          "&:hover": {
            backgroundColor: "#38879f",
          },
        }}
      >
        Next
      </Button>
    </form>
  );
};

const SocialInputs = ({ handleCurrentStep }) => {
  const formik = useFormik({
    initialValues: {
      website: "",
      linkedin: "",
      instagram: "",
    },
    validationSchema: object({
      website: string().required("This field is required"),
      linkedin: string().required("This field is required"),
      instagram: string().required("This field is required"),
    }),
    onSubmit: (values) => handleSubmit(values),
  });

  const handleSubmit = (values) => {
    handleCurrentStep(values, true);
  };
  return (
    <form onSubmit={formik.handleSubmit}>
      <StyledInput
        name={"linkedin"}
        placeholder={"Enter Linkedin Link"}
        handleChange={formik.handleChange}
        handleBlur={formik.handleBlur}
        errors={formik.errors}
        touched={formik.touched}
        value={formik.values.linkedin}
      />
      <StyledInput
        name={"website"}
        placeholder={"Enter Website Link"}
        handleChange={formik.handleChange}
        handleBlur={formik.handleBlur}
        errors={formik.errors}
        touched={formik.touched}
        value={formik.values.website}
      />
      <StyledInput
        name={"instagram"}
        placeholder={"Enter Instagram Link"}
        handleChange={formik.handleChange}
        handleBlur={formik.handleBlur}
        errors={formik.errors}
        touched={formik.touched}
        value={formik.values.instagram}
      />
      <Button
        type="submit"
        disabled={
          formik.isSubmitting || Object.entries(formik.errors).length > 0
        }
        sx={{
          width: "100%",
          height: "55px",
          marginTop: "15px",
          background: "#38879f",
          color: "#fff",
          fontWeight: 600,
          borderRadius: "10px",
          "&.Mui-disabled": {
            background: "#adcdd7",
            color: "#fff",
          },
          "&:hover": {
            backgroundColor: "#38879f",
          },
        }}
      >
        Next
      </Button>
    </form>
  );
};

const Experience = ({ handleCurrentStep, currentValues }) => {
  const formik = useFormik({
    initialValues: {
      experience: currentValues?.experience,
    },
    validationSchema: object({
      experience: number().required("This field is required").max(50),
    }),
    onSubmit: (values) => handleSubmit(values),
  });

  const handleSubmit = (values) => {
    handleCurrentStep(values);
  };
  console.log(formik.errors, "errors");
  return (
    <form onSubmit={formik.handleSubmit}>
      <StyledInput
        type={"number"}
        name={"experience"}
        placeholder={"Enter Experience"}
        handleChange={(e) => {
          if (!e.target.value || /^\d+(\.\d)?$/.test(e.target.value)) {
            formik.handleChange(e);
          }
        }}
        handleBlur={formik.handleBlur}
        errors={formik.errors}
        touched={formik.touched}
        value={formik.values.experience}
      />
      <Button
        type="submit"
        disabled={
          formik.isSubmitting ||
          Object.entries(formik.errors).length > 0 ||
          !formik.values.experience
        }
        sx={{
          width: "100%",
          height: "55px",
          marginTop: "15px",
          background: "#38879f",
          color: "#fff",
          fontWeight: 600,
          borderRadius: "10px",
          "&.Mui-disabled": {
            background: "#adcdd7",
            color: "#fff",
          },
          "&:hover": {
            backgroundColor: "#38879f",
          },
        }}
      >
        Next
      </Button>
    </form>
  );
};

const LevelCareExp = ({ handleCurrentStep, currentValues }) => {
  const formik = useFormik({
    initialValues: {
      experienceLevel: currentValues?.experienceLevel,
    },
    validationSchema: object({
      experienceLevel: string().required("This field is required"),
    }),
    onSubmit: (values) => handleSubmit(values),
  });

  const handleSubmit = (values) => {
    handleCurrentStep(values);
  };
  console.log(formik.values);
  return (
    <form onSubmit={formik.handleSubmit}>
      <Select
        name="experienceLevel"
        label="Select your level of care experience"
        renderValue={(value) => (
          <Typography sx={value.includes("Select") ? { color: "#7b8c9d" } : {}}>
            {value}
          </Typography>
        )}
        inputProps={{
          style: {
            padding: "10px",
          },
        }}
        value={
          experienceLevels[formik.values?.experienceLevel] ||
          "Select your level of care experience"
        }
        sx={{
          padding: "10px 5px 10px 0",
          background: "#eff6f8",
          color: "#0d2645",
          fontSize: "16px",
          width: "100%",
          height: "50px",
          textAlign: "left",
          borderRadius: "10px",
          "&:after": {
            borderBottom: "none",
          },
          "&:before": {
            borderBottom: "none",
          },
          "&:hover:not(.Mui-disabled, .Mui-error):before": {
            borderBottom: "none",
          },
          "&.Mui-focused": {
            border: "none",
            outline: "none",
          },
        }}
        onChange={formik.handleChange}
      >
        <MenuItem
          value="med/sur"
          sx={{
            height: "50px",
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            color: "#0d2645",
            padding: "0 8px",
            "&.Mui-selected": {
              background: "none",
            },
          }}
        >
          <Typography fontWeight={600} fontSize={16} lineHeight={29}>
            Med/Sur
          </Typography>
          <Checkbox
            sx={{
              borderRadius: "50%",
            }}
            icon={<img src={tickCircle} alt="checked" />}
            checkedIcon={<img src={tickCheckedCircle} alt="checked" />}
            checked={formik.values.experienceLevel == "med/sur"}
          />
        </MenuItem>
        <Divider
          sx={{ marginTop: "0 !important", marginBottom: "0 !important" }}
        />
        <MenuItem
          value="telemetry"
          sx={{
            height: "50px",
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            color: "#0d2645",
            padding: "0 8px",
            "&.Mui-selected": {
              background: "none",
            },
          }}
        >
          <Typography fontWeight={600} fontSize={16} lineHeight={29}>
            Telemetry
          </Typography>
          <Checkbox
            sx={{
              borderRadius: "50%",
            }}
            icon={<img src={tickCircle} alt="checked" />}
            checkedIcon={<img src={tickCheckedCircle} alt="checked" />}
            checked={formik.values.experienceLevel == "telemetry"}
          />
        </MenuItem>
        <Divider
          sx={{ marginTop: "0 !important", marginBottom: "0 !important" }}
        />
        <MenuItem
          value="icu"
          sx={{
            height: "50px",
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            color: "#0d2645",
            padding: "0 8px",
            "&.Mui-selected": {
              background: "none",
            },
          }}
        >
          <Typography fontWeight={600} fontSize={16} lineHeight={29}>
            ICU
          </Typography>
          <Checkbox
            sx={{
              borderRadius: "50%",
            }}
            icon={<img src={tickCircle} alt="checked" />}
            checkedIcon={<img src={tickCheckedCircle} alt="checked" />}
            checked={formik.values.experienceLevel == "icu"}
          />
        </MenuItem>
        <Divider
          sx={{ marginTop: "0 !important", marginBottom: "0 !important" }}
        />
        <MenuItem
          value="ccu"
          sx={{
            height: "50px",
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            color: "#0d2645",
            padding: "0 8px",
            "&.Mui-selected": {
              background: "none",
            },
          }}
        >
          <Typography fontWeight={600} fontSize={16} lineHeight={29}>
            CCU
          </Typography>
          <Checkbox
            sx={{
              borderRadius: "50%",
            }}
            icon={<img src={tickCircle} alt="checked" />}
            checkedIcon={<img src={tickCheckedCircle} alt="checked" />}
            checked={formik.values.experienceLevel == "ccu"}
          />
        </MenuItem>
        <Divider
          sx={{ marginTop: "0 !important", marginBottom: "0 !important" }}
        />
        <MenuItem
          value="ed"
          sx={{
            height: "50px",
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            color: "#0d2645",
            padding: "0 8px",
            "&.Mui-selected": {
              background: "none",
            },
          }}
        >
          <Typography fontWeight={600} fontSize={16} lineHeight={29}>
            ED
          </Typography>
          <Checkbox
            sx={{
              borderRadius: "50%",
            }}
            icon={<img src={tickCircle} alt="checked" />}
            checkedIcon={<img src={tickCheckedCircle} alt="checked" />}
            checked={formik.values.experienceLevel == "ed"}
          />
        </MenuItem>
        <Divider
          sx={{ marginTop: "0 !important", marginBottom: "0 !important" }}
        />
        <MenuItem
          value="pacu"
          sx={{
            height: "50px",
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            color: "#0d2645",
            padding: "0 8px",
            "&.Mui-selected": {
              background: "none",
            },
          }}
        >
          <Typography fontWeight={600} fontSize={16} lineHeight={29}>
            PACU
          </Typography>
          <Checkbox
            sx={{
              borderRadius: "50%",
            }}
            icon={<img src={tickCircle} alt="checked" />}
            checkedIcon={<img src={tickCheckedCircle} alt="checked" />}
            checked={formik.values.experienceLevel == "pacu"}
          />
        </MenuItem>
      </Select>
      <Button
        type="submit"
        disabled={
          formik.isSubmitting ||
          Object.entries(formik.errors).length > 0 ||
          !formik.values.experienceLevel
        }
        sx={{
          width: "100%",
          height: "55px",
          marginTop: "15px",
          background: "#38879f",
          color: "#fff",
          fontWeight: 600,
          borderRadius: "10px",
          "&.Mui-disabled": {
            background: "#adcdd7",
            color: "#fff",
          },
          "&:hover": {
            backgroundColor: "#38879f",
          },
        }}
      >
        Next
      </Button>
    </form>
  );
};

const HospitalDescription = ({ handleCurrentStep, currentValues }) => {
  const formik = useFormik({
    initialValues: {
      bio: currentValues?.bio,
    },
    validationSchema: object({
      bio: string()
        .required("This field is required")
        .min(3, "Last Name must be at least 3 characters")
        .max(255, "Last Name must be at most 255 characters"),
    }),
    onSubmit: (values) => handleSubmit(values),
  });
  console.log(currentValues?.bio);
  const handleSubmit = (values) => {
    handleCurrentStep(values);
  };
  console.log(formik.values, "biosdfdsf");
  return (
    <form onSubmit={formik.handleSubmit}>
      <TextareaAutosize
        name="bio"
        className="area-desc"
        placeholder="A brief bio highlights your unique skills and experiences."
        minRows={4}
        value={formik.values.bio}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        style={{
          padding: "10px 5px 10px 20px",
          background: "#eff6f8",
          width: "100%",
          border: "none",
          height: "30px",
          borderRadius: "20px",
          margin: "10px 0",
          fontFamily: "Lato, sans-serif",
          fontSize: "15px",
        }}
      />
      {/* <Box> */}
      {formik.errors["bio"] && formik.touched["bio"] && (
        <Typography textAlign={"left"} variant="body2" color="red">
          {formik.errors["bio"]}
        </Typography>
      )}
      {/* </Box> */}
      <Button
        type="submit"
        disabled={
          formik.isSubmitting || Object.entries(formik.errors).length > 0
        }
        sx={{
          width: "100%",
          height: "55px",
          marginTop: "15px",
          background: "#38879f",
          color: "#fff",
          fontWeight: 600,
          borderRadius: "10px",
          "&.Mui-disabled": {
            background: "#adcdd7",
            color: "#fff",
          },
          "&:hover": {
            backgroundColor: "#38879f",
          },
        }}
      >
        Next
      </Button>
    </form>
  );
};

const PlacesInputs = ({ handleCurrentStep }) => {
  const [showList, setShowList] = useState(false);

  const formik = useFormik({
    initialValues: {
      address: "",
      city: "",
      province: "",
      postalCode: "",
    },
    validationSchema: object({
      address: string().required("This field is required"),
      city: string().required("This field is required"),
      province: string().required("This field is required"),
      postalCode: string().required("This field is required"),
    }),
    onSubmit: (values) => handleSubmit(values),
  });

  const handleSubmit = (values) => {
    handleCurrentStep(values);
  };

  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = useGoogle({
    apiKey: process.env.REACT_APP_PLACES_KEY,
    options: {
      componentRestrictions: { country: "IN" },
    },
  });

  const getDetails = (place_id) => {
    setShowList(false);
    placesService?.getDetails(
      {
        placeId: place_id,
      },
      (placeDetails) => {
        const address = getAddressObject(placeDetails?.address_components);
        formik.setFieldValue("address", placeDetails?.name);
        formik.setFieldValue("city", address?.city);
        formik.setFieldValue("province", address?.region);
        formik.setFieldValue("postalCode", address?.postal_code);
      }
    );
  };

  return (
    <Box onClick={() => setShowList(false)}>
      <form onSubmit={formik.handleSubmit}>
        <StyledInput
          name={"address"}
          placeholder={"Enter Address"}
          handleChange={(e) => {
            getPlacePredictions({ input: e.target.value });
            setShowList(true);
            formik.setFieldValue("city", "");
            formik.setFieldValue("province", "");
            formik.setFieldValue("postalCode", "");
            formik.handleChange(e);
          }}
          handleBlur={formik.handleBlur}
          errors={formik.errors}
          touched={formik.touched}
          value={formik.values.address}
        />
        {!isPlacePredictionsLoading && showList && (
          <Box
            sx={{
              width: 550,
              height: 200,
              maxWidth: 360,
              overflow: "auto",
              border: "1px solid grey",
            }}
          >
            {placePredictions.map((item) => {
              return (
                <ListItem
                  key={item?.place_id}
                  onClick={(e) => {
                    e.stopPropagation();
                    getDetails(item?.place_id);
                  }}
                  component="div"
                  disablePadding
                >
                  <ListItemButton>
                    <ListItemText primary={item.description} />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </Box>
        )}
        <StyledInput
          name={"city"}
          placeholder={"Enter City"}
          handleChange={formik.handleChange}
          handleBlur={formik.handleBlur}
          errors={formik.errors}
          touched={formik.touched}
          value={formik.values.city}
        />
        <Box display={"flex"}>
          <StyledInput
            name={"province"}
            placeholder={"Enter Province"}
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            errors={formik.errors}
            touched={formik.touched}
            value={formik.values.province}
            style={{ width: "50%", marginRight: "10px" }}
          />
          <StyledInput
            name={"postalCode"}
            placeholder={"Enter Postal Code"}
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            errors={formik.errors}
            touched={formik.touched}
            value={formik.values.postalCode}
            style={{ width: "50%", marginLeft: "10px" }}
          />
        </Box>
        <Button
          type="submit"
          disabled={
            formik.isSubmitting || Object.entries(formik.errors).length > 0
          }
          sx={{
            width: "100%",
            height: "55px",
            marginTop: "15px",
            background: "#38879f",
            color: "#fff",
            fontWeight: 600,
            borderRadius: "10px",
            "&.Mui-disabled": {
              background: "#adcdd7",
              color: "#fff",
            },
            "&:hover": {
              backgroundColor: "#38879f",
            },
          }}
        >
          Next
        </Button>
      </form>
    </Box>
  );
};

const UploadResume = ({ handleCurrentStep, currentValues }) => {
  const [fileData, setFileData] = useState(currentValues?.resume);
  const [progress, setProgress] = useState(0);
  const [uploadedResumeDetails, setUploadedResumeDetails] = useState(null);
  const [fileShow, setFileShow] = useState(false);
  useEffect(() => {
    if (currentValues?.resume) {
      setFileData({ Location: currentValues?.resume });
      setUploadedResumeDetails(currentValues?.uploadedResumeDetails);
      setFileShow(true);
      setProgress(100);
    }
  }, [currentValues?.resume]);

  const handleFileUpload = (e) => {
    if (e.target.files[0]?.size > 2000000) {
      toast.error("File size should be less than or equal to 2MB.");
    } else {
      const uploadedFile = e.target.files[0];
      setUploadedResumeDetails({
        name: uploadedFile?.name,
        size: formatBytes(uploadedFile?.size),
      });
      setFileShow(true);
      const formData = new FormData();
      console.log(e.target.files[0], "fsdfsfsdf");
      formData.append("file", e.target.files[0]);
      formData.append("folder", "profile");
      setProgress(50);
      FileUpload(formData)
        .then((resp) => {
          setFileData(resp.data.data);
          setProgress(100);
        })
        .catch((Err) => {
          setFileShow(false);
          toast.error(Err.message);
        });
    }
  };

  const deleteUpload = () => {
    const payload = {
      url: fileData?.Location,
    };
    DeleteUploadedFile(payload)
      .then((resp) => {
        toast.success(resp.message);
        setProgress(0);
        setFileShow(null);
      })
      .catch((Err) => {
        toast.error(Err.message);
      });
  };

  const handleSubmit = () => {
    handleCurrentStep({ resume: fileData?.Location, uploadedResumeDetails });
  };
  return (
    <Box>
      {fileShow ? (
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          border={"1px solid #38879f"}
          borderRadius={"10px"}
          padding={"10px"}
          sx={{
            background: "#f1f6f8",
          }}
        >
          <Box>
            <img src={FileIcon} alt="file" />
          </Box>
          <Box
            sx={{
              textAlign: "left",
              padding: "0 20px",
              width: "100%",
            }}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"space-between"}
          >
            <Typography
              fontSize={"12px"}
              lineHeight={"14.4px"}
              fontWeight={400}
              color="#0D2645"
              sx={{
                position: "relative",
                top: "6px",
              }}
            >
              {uploadedResumeDetails?.name}
            </Typography>
            <Typography
              fontSize={"12px"}
              lineHeight={"14.4px"}
              fontWeight={400}
              color="#1111117A"
            >
              {uploadedResumeDetails?.size}
            </Typography>
            <LinearProgress
              variant="determinate"
              value={progress}
              sx={{
                top: "-5px",
                borderRadius: "25px",
                height: "6px",
                "& .MuiLinearProgress-bar": {
                  background: "#38879f",
                  borderRadius: "25px",
                },
              }}
            />
          </Box>
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"space-between"}
          >
            <img
              src={CloseIcon}
              alt="close"
              style={{ cursor: "pointer" }}
              onClick={() => {
                deleteUpload();
              }}
            />
            <Typography
              fontSize={"12px"}
              lineHeight={"14.4px"}
              fontWeight={400}
              color="#38879F"
            >
              {progress}%
            </Typography>
          </Box>
        </Box>
      ) : (
        <>
          <label for="file-upload">
            {/* <img
          src={fileLink ? fileLink : ContactIcon}
          alt="upload-resume"
          width={140}
          style={{ padding: "20px 0 20px 30px" }}
        /> */}

            <img
              src={UploadDocIcon}
              alt="upload-resume"
              width={"100%"}
              style={{ cursor: "pointer" }}
            />
          </label>
          <input
            type="file"
            id="file-upload"
            itemType=""
            style={{
              display: "none",
            }}
            accept="application/pdf"
            onChange={handleFileUpload}
          />
        </>
      )}
      <Button
        onClick={handleSubmit}
        disabled={progress != 100}
        sx={{
          width: "100%",
          height: "55px",
          marginTop: "15px",
          background: "#38879f",
          color: "#fff",
          fontWeight: 600,
          borderRadius: "10px",
          "&.Mui-disabled": {
            background: "#adcdd7",
            color: "#fff",
          },
          "&:hover": {
            backgroundColor: "#38879f",
          },
        }}
      >
        Next
      </Button>
    </Box>
  );
};

const UploadLicense = ({ handleCurrentStep, currentValues }) => {
  const [fileData, setFileData] = useState(null);
  const [progress, setProgress] = useState(0);
  const [uploadedLicenseDetails, setUploadedLicenseDetails] = useState(null);
  const [fileShow, setFileShow] = useState(false);
  useEffect(() => {
    if (currentValues?.license) {
      setFileData({ Location: currentValues?.license });
      setUploadedLicenseDetails(currentValues?.uploadedLicenseDetails);
      setFileShow(true);
      setProgress(100);
    }
  }, [currentValues?.license]);
  const handleFileUpload = (e) => {
    if (e.target.files[0]?.size > 2000000) {
      toast.error("File size should be less than or equal to 2MB.");
    } else {
      const uploadedFile = e.target.files[0];
      setUploadedLicenseDetails({
        name: uploadedFile?.name,
        size: formatBytes(uploadedFile?.size),
      });
      setFileShow(true);
      const formData = new FormData();
      console.log(e.target.files[0], "fsdfsfsdf");
      formData.append("file", e.target.files[0]);
      formData.append("folder", "profile");
      setProgress(50);
      FileUpload(formData)
        .then((resp) => {
          setFileData(resp.data.data);
          setProgress(100);
        })
        .catch((Err) => {
          setFileShow(false);
          toast.error(Err.message);
        });
    }
  };

  const deleteUploadFunc = () => {
    const payload = {
      url: fileData?.Location,
    };
    DeleteUploadedFile(payload)
      .then((resp) => {
        toast.success(resp.message);
        setProgress(0);
        setFileShow(null);
      })
      .catch((Err) => {
        toast.error(Err.message);
      });
  };

  const handleSubmit = () => {
    handleCurrentStep({ license: fileData?.Location, uploadedLicenseDetails });
  };
  return (
    <Box>
      {fileShow ? (
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          border={"1px solid #38879f"}
          borderRadius={"10px"}
          padding={"10px"}
          sx={{
            background: "#f1f6f8",
          }}
        >
          <Box>
            <img src={FileIcon} alt="file" />
          </Box>
          <Box
            sx={{
              textAlign: "left",
              padding: "0 20px",
              width: "100%",
            }}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"space-between"}
          >
            <Typography
              fontSize={"12px"}
              lineHeight={"14.4px"}
              fontWeight={400}
              color="#0D2645"
              sx={{
                position: "relative",
                top: "6px",
              }}
            >
              {uploadedLicenseDetails?.name}
            </Typography>
            <Typography
              fontSize={"12px"}
              lineHeight={"14.4px"}
              fontWeight={400}
              color="#1111117A"
            >
              {uploadedLicenseDetails?.size}
            </Typography>
            <LinearProgress
              variant="determinate"
              value={progress}
              sx={{
                top: "-5px",
                borderRadius: "25px",
                height: "6px",
                "& .MuiLinearProgress-bar": {
                  background: "#38879f",
                  borderRadius: "25px",
                },
              }}
            />
          </Box>
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"space-between"}
          >
            <img
              src={CloseIcon}
              alt="close"
              style={{ cursor: "pointer" }}
              onClick={() => {
                deleteUploadFunc();
              }}
            />
            <Typography
              fontSize={"12px"}
              lineHeight={"14.4px"}
              fontWeight={400}
              color="#38879F"
            >
              {progress}%
            </Typography>
          </Box>
        </Box>
      ) : (
        <>
          <label for="file-upload">
            {/* <img
          src={fileLink ? fileLink : ContactIcon}
          alt="upload-resume"
          width={140}
          style={{ padding: "20px 0 20px 30px" }}
        /> */}

            <img
              src={DocIcon}
              alt="upload-resume"
              width={"100%"}
              style={{ cursor: "pointer" }}
            />
          </label>
          <input
            type="file"
            id="file-upload"
            itemType=""
            style={{
              display: "none",
            }}
            accept="application/pdf"
            onChange={handleFileUpload}
          />
        </>
      )}
      <Button
        onClick={handleSubmit}
        disabled={progress != 100}
        sx={{
          width: "100%",
          height: "55px",
          marginTop: "15px",
          background: "#38879f",
          color: "#fff",
          fontWeight: 600,
          borderRadius: "10px",
          "&.Mui-disabled": {
            background: "#adcdd7",
            color: "#fff",
          },
          "&:hover": {
            backgroundColor: "#38879f",
          },
        }}
      >
        Next
      </Button>
    </Box>
  );
};

const SelectLicensedStates = ({ handleCurrentStep }) => {
  const [licensedStates, setLicensedStates] = useState(["Select States"]);

  const handleChangeStates = (i) => {
    const filteredArr = licensedStates.filter((n) => n != i);
    setLicensedStates(filteredArr);
  };
  const handleSubmit = () => {
    const filteredArr = [...usaStates].filter((i) => {
      return licensedStates.some((n) => n == i.name);
    });
    handleCurrentStep({ licensedStates: filteredArr }, true);
  };
  return (
    <Box className="state-wrapper">
      {/* <form onSubmit={formik.handleSubmit}> */}
      <Select
        name="licensedStates"
        placeholder="Select States"
        multiple
        renderValue={(values) => (
          <Typography sx={{ color: "#7b8c9d" }}>Select States</Typography>
        )}
        inputProps={{
          style: {
            padding: "10px",
          },
        }}
        value={licensedStates}
        sx={{
          padding: "10px 5px 10px 0",
          background: "#eff6f8",
          color: "#0d2645",
          fontSize: "16px",
          width: "100%",
          height: "50px",
          textAlign: "left",
          borderRadius: "10px",
          "&:after": {
            borderBottom: "none",
          },
          "&:before": {
            borderBottom: "none",
          },
          "&:hover:not(.Mui-disabled, .Mui-error):before": {
            borderBottom: "none",
          },
          "&.Mui-focused": {
            border: "none",
            outline: "none",
          },
        }}
        onChange={(e) => {
          setLicensedStates(e.target.value);
        }}
      >
        {usaStates.map((stateObj) => {
          return (
            <MenuItem
              value={stateObj?.name}
              key={stateObj?.code}
              sx={{
                height: "50px",
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                color: "#0d2645",
                padding: "0 8px",
                "&.Mui-selected": {
                  background: "none",
                },
              }}
            >
              <Typography fontWeight={600} fontSize={16} lineHeight={29}>
                {stateObj?.name}
              </Typography>
              <Checkbox
                sx={{
                  borderRadius: "50%",
                }}
                icon={<img src={tickCircle} alt="checked" />}
                checkedIcon={<img src={tickCheckedCircle} alt="checked" />}
                checked={licensedStates?.includes(stateObj?.name)}
              />
            </MenuItem>
          );
        })}
      </Select>
      <Box
        sx={{
          marginTop: "20px",
          textAlign: "left",
        }}
      >
        {licensedStates
          .filter((item) => item != "Select States")
          ?.map((i) => {
            return (
              <Chip
                sx={{
                  bgcolor: "#38879f",
                  margin: "10px",
                  padding: "10px 5px",
                  borderRadius: "10px",
                }}
                key={i}
                label={
                  <Box>
                    <Typography color="#fff">
                      {i}&nbsp;
                      <span
                        style={{ marginLeft: "5px", cursor: "pointer" }}
                        onClick={() => handleChangeStates(i)}
                      >
                        X
                      </span>
                    </Typography>
                  </Box>
                }
              />
            );
          })}
      </Box>
      <Button
        onClick={handleSubmit}
        sx={{
          width: "100%",
          height: "55px",
          marginTop: "15px",
          background: "#38879f",
          color: "#fff",
          fontWeight: 600,
          borderRadius: "10px",
          "&.Mui-disabled": {
            background: "#adcdd7",
            color: "#fff",
          },
          "&:hover": {
            backgroundColor: "#38879f",
          },
        }}
      >
        Next
      </Button>
      {/* </form> */}
    </Box>
  );
};

const LogoUpload = ({ handleCurrentStep, currentValues }) => {
  const [fileLink, setFileLink] = useState(currentValues?.profilePicture);
  const handleFileUpload = (e) => {
    if (e.target.files[0]?.size > 2000000) {
      toast.error("File size should be less than or equal to 2MB.");
    } else {
      const formData = new FormData();
      if (e.target.files[0]) {
        formData.append("file", e.target.files[0]);
        formData.append("folder", "profile");
        FileUpload(formData)
          .then((resp) => {
            console.log(resp, "uploaded");
            setFileLink(resp.data.data?.Location);
          })
          .catch((Err) => {
            toast.error(Err.message);
          });
      }
    }
  };

  const handleSubmit = () => {
    handleCurrentStep({ profilePicture: fileLink });
  };
  return (
    <Box>
      <label
        style={{ position: "relative" }}
        className="ulpoad-label"
        for="file-upload"
      >
        <img
          className="flie-upload-img"
          src={fileLink ? fileLink : ContactIcon}
          alt="upload-logo"
          style={
            fileLink
              ? { width: "140px", borderRadius: "50%", height: "140px" }
              : {
                  width: 140,
                  height: 140,
                  padding: 0,
                  borderRadius: "50%",
                  cursor: "pointer",
                }
          }
        />
        <img
          className="camera-imgg"
          src={CameraIcon}
          alt="upload-logo"
          style={{ position: "absolute", right: "-15px", bottom: "15px" }}
        />
      </label>
      <input
        type="file"
        id="file-upload"
        itemType=""
        style={{
          display: "none",
        }}
        accept="image/*"
        onChange={handleFileUpload}
      />
      <Button
        onClick={handleSubmit}
        disabled={!fileLink}
        sx={{
          width: "80%",
          height: "55px",
          marginTop: "15px",
          background: "#38879f",
          color: "#fff",
          fontWeight: 600,
          borderRadius: "10px",
          "&.Mui-disabled": {
            background: "#adcdd7",
            color: "#fff",
          },
          "&:hover": {
            backgroundColor: "#38879f",
          },
        }}
      >
        Next
      </Button>
    </Box>
  );
};

const getFileName = (FileData) => {
  const splittedArr = FileData.Key?.split(".");
  const joinedArr =
    splittedArr[splittedArr?.length - 2] +
    "." +
    splittedArr[splittedArr?.length - 1];
  return joinedArr;
};
