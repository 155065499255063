import { Input, Typography, Box, Button, InputAdornment } from "@mui/material";
import { Formik } from "formik";
import AppLogo from "../../../../assets/icons/app-logo.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { resetPassword } from "../../../../controller/services/auth";
import toast from "react-hot-toast";
import PasswordIcon from "../../../../assets/icons/password.svg";
import BackIcon from "../../../../assets/icons/back-dark.svg";
import EyeIcon from "../../../../assets/icons/eye.svg";
import EyeSlashIcon from "../../../../assets/icons/eye-slash.svg";
import { useState } from "react";
export default function ResetPassword() {
  const [passwordVisibility, setPasswordVisibility] = useState("");
  const navigate = useNavigate();
  const params = useLocation();

  const handleSubmit = (values) => {
    const payload = {
      password: values?.password,
      token: params?.state?.token,
    };
    resetPassword(payload)
      .then((resp) => {
        navigate("/password-changed");
        toast.success(resp.message);
      })
      .catch((Err) => {
        toast.error(Err.message);
      });
  };
  return (
    <Box
      sx={{
        padding: {
          xs: "30px",
          sm: "0 30px",
          md: "0 70px",
          lg: "0 70px",
          xl: "0 70px",
        },
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: {
          xs: "auto",
          sm: "auto",
          md: "100vh",
          lg: "100vh",
          xl: "100vh",
        },
      }}
    >
      <Box
        border={2}
        borderColor={"#38879F"}
        borderRadius={5}
        sx={{
          position: "relative",
          width: "100%",
        }}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Box
          sx={{
            padding: {
              xs: "30px 20px",
              sm: "45px",
              md: "45px",
              lg: "45px",
              xl: "70px 45px",
            },
            width: {
              xs: "100%",
              sm: "100%",
              md: "70%",
              lg: "35%",
              xl: "30%",
            },
          }}
        >
          <img
            src={BackIcon}
            alt="back"
            style={{
              position: "absolute",
              left: "10vw",
              top: "15vh",
              cursor: "pointer",
            }}
            className="back-icon"
            onClick={() => navigate(-1)}
          />
          <img className="app-logo-role" src={AppLogo} alt="logo" />
          <Typography
            variant="h3"
            color="#0d2645"
            fontWeight={600}
            margin={"20px 0 10px"}
            sx={{
              fontSize: {
                xs: 20,
                sm: 22,
                md: 24,
                lg: 26,
                xl: 26,
              },
              lineHeight: {
                xs: "27px",
                sm: "28px",
                md: "31.2px",
                lg: "31.2px",
                xl: "31.2px",
              },
            }}
          >
            Reset Password
          </Typography>
          <Typography
            variant="h6"
            color="#8391a0"
            sx={{
              padding: {
                xs: "0 0 30px",
                sm: "10px",
                md: "10px",
                lg: "10px",
                xl: "10px",
              },
              fontSize: {
                xs: "12px",
                sm: "14px",
                md: "16px",
                lg: "16px",
                xl: "16px",
              },
              lineHeight: {
                xs: "20px",
                sm: "24px",
                md: "25px",
                lg: "25px",
                xl: "25px",
              },
            }}
            fontWeight={400}
          >
            Set the new password by entering new and confirm password below.
          </Typography>

          <Formik
            initialValues={{ password: "", confirmPassword: "" }}
            validate={(values) => {
              const errors = {};
              const passRegex = RegExp("^.{8,15}$");
              if (!values.password) {
                errors.password = "Required";
              }
              if (!values.confirmPassword) {
                errors.confirmPassword = "Required";
              }
              if (values.password !== values.confirmPassword) {
                errors.password = "Password and Confirm password should match.";
              }
              if (!passRegex.test(values.password)) {
                errors.password = "Must contain at least 8 or more characters";
              }
              if (!passRegex.test(values.confirmPassword)) {
                errors.confirmPassword =
                  "Must contain at least 8 or more characters";
              }
              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              handleSubmit(values);
              setSubmitting(false);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
              <form onSubmit={handleSubmit}>
                <Box
                  sx={{
                    paddingBottom: {
                      xs: 0,
                      sm: "20px",
                      md: "20px",
                      lg: "20px",
                      xl: "20px",
                    },
                  }}
                >
                  <Input
                    type={
                      passwordVisibility !== "password" ? "password" : "text"
                    }
                    name="password"
                    placeholder="Enter Password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    startAdornment={<img src={PasswordIcon} alt="password" />}
                    inputProps={{
                      style: {
                        padding: "10px",
                      },
                    }}
                    endAdornment={
                      <InputAdornment
                        position="end"
                        sx={{ paddingRight: "10px", cursor: "pointer" }}
                      >
                        {passwordVisibility == "password" ? (
                          <img
                            alt=""
                            src={EyeIcon}
                            onClick={() => setPasswordVisibility("")}
                            style={{ cursor: "pointer" }}
                          />
                        ) : (
                          <img
                            alt=""
                            src={EyeSlashIcon}
                            onClick={() => setPasswordVisibility("password")}
                            style={{ cursor: "pointer" }}
                          />
                        )}
                      </InputAdornment>
                    }
                    sx={{
                      padding: "10px 5px 10px 20px",
                      background: "#eff6f8",
                      width: "100%",
                      height: "50px",
                      borderRadius: "10px",
                      "&:after": {
                        borderBottom: "none",
                      },
                      "&:before": {
                        borderBottom: "none",
                      },
                      "&:hover:not(.Mui-disabled, .Mui-error):before": {
                        borderBottom: "none",
                      },
                      "&.Mui-focused": {
                        borderBottom: "none",
                      },
                    }}
                  />
                  {errors.password && touched.password && (
                    <Typography textAlign={"left"} variant="body2" color="red">
                      {errors.password}
                    </Typography>
                  )}
                </Box>
                <Box>
                  <Input
                    type={
                      passwordVisibility !== "confirmPassword"
                        ? "password"
                        : "text"
                    }
                    name="confirmPassword"
                    placeholder="Enter Confirm Password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.confirmPassword}
                    startAdornment={<img src={PasswordIcon} alt="password" />}
                    endAdornment={
                      <InputAdornment
                        position="end"
                        sx={{ paddingRight: "10px", cursor: "pointer" }}
                      >
                        {passwordVisibility == "confirmPassword" ? (
                          <img
                            alt=""
                            src={EyeIcon}
                            onClick={() => setPasswordVisibility("")}
                            style={{ cursor: "pointer" }}
                          />
                        ) : (
                          <img
                            alt=""
                            src={EyeSlashIcon}
                            onClick={() =>
                              setPasswordVisibility("confirmPassword")
                            }
                            style={{ cursor: "pointer" }}
                          />
                        )}
                      </InputAdornment>
                    }
                    inputProps={{
                      style: {
                        padding: "10px",
                      },
                    }}
                    sx={{
                      padding: "10px 5px 10px 20px",
                      background: "#eff6f8",
                      width: "100%",
                      height: "50px",
                      borderRadius: "10px",
                      "&:after": {
                        borderBottom: "none",
                      },
                      "&:before": {
                        borderBottom: "none",
                      },
                      "&:hover:not(.Mui-disabled, .Mui-error):before": {
                        borderBottom: "none",
                      },
                      "&.Mui-focused": {
                        borderBottom: "none",
                      },
                    }}
                  />
                  {errors.confirmPassword && touched.confirmPassword && (
                    <Typography textAlign={"left"} variant="body2" color="red">
                      {errors.confirmPassword}
                    </Typography>
                  )}
                </Box>
                <Button
                  type="submit"
                  disabled={isSubmitting || Object.entries(errors).length > 0}
                  sx={{
                    width: "100%",
                    height: "55px",
                    marginTop: "15px",
                    background: "#38879f",
                    color: "#fff",
                    fontWeight: 600,
                    borderRadius: "10px",
                    "&.Mui-disabled": {
                      background: "#adcdd7",
                      color: "#fff",
                    },
                    "&:hover": {
                      backgroundColor: "#38879f",
                    },
                  }}
                >
                  Submit
                </Button>
              </form>
            )}
          </Formik>
        </Box>
      </Box>
    </Box>
  );
}
